import React, { MouseEvent, useEffect, useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import CheckIcon from '@mui/icons-material/Check';

import Input from '../ui-kit/Input';
import Budget from '../../config/budget';
import isBudgetValid from '../../utils';
import Accordion, { AccordionDetails, AccordionSummary } from '../ui-kit/Accordion';
import IconButton from '../ui-kit/Button/IconButton';
import { Occasion } from '../../interfaces/Profile';
import Button from '../ui-kit/Button';
import Contact from '../../interfaces/Contact';

interface ConnectionCardProps {
  name: string;
  occasions: Occasion[] | undefined;
  id: string;
  opened: boolean;
  isInvited?: boolean;
  deleteConnection: (
    connection: Pick<Contact, 'id' | 'name'>,
    confirmed?: boolean,
    e?: MouseEvent<HTMLButtonElement>,
  ) => Promise<void>;
}

const ConnectionCard: React.FC<ConnectionCardProps> = ({
  id,
  name,
  occasions,
  deleteConnection,
  opened,
  isInvited = false,
}) => {
  const navigate = useNavigate();
  const [budgetValue, setBudgetValue] = useState<string>('');
  const [showBudgetField, setShowBudgetField] = useState<string>('');

  const budgetInpRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (showBudgetField) {
      budgetInpRef.current?.focus?.();
    }
  }, [showBudgetField]);

  const handleSetBudgetValue = (value: string): void => {
    const sanitizedValue = value.replace(/^0+/, '');
    setBudgetValue(sanitizedValue);
  };

  const openRecommends = (connectionId: string, occasionId: string) => {
    if (isBudgetValid(budgetValue)) {
      const budget = Number.parseFloat(budgetValue).toFixed(2);
      navigate(`/connections/${connectionId}?occasionId=${occasionId}&budget=${budget}`);
    }
  };

  const getDateLabel = (occasion: Occasion): string => {
    const now = dayjs().toISOString().split('T')[0];
    const today = dayjs(`${now}T00:00:00.000Z`);
    let date = dayjs(occasion.date);
    let diff = date.diff(today, 'days');

    if (diff === 0) {
      return 'today';
    }

    if (diff < 0 && !occasion.yearly) {
      diff *= -1;
      return `was ${diff} day${diff > 1 ? 's' : ''} ago`;
    }

    while (diff < 0) {
      date = date.add(1, 'year');
      diff = date.diff(today, 'days');
    }

    if (diff === 0) {
      return 'today';
    }

    return `in ${diff} day${diff > 1 ? 's' : ''}`;
  };

  return (
    <div className="mb-3">
      <Accordion
        style={{
          borderRadius: '12px',
        }}
        sx={{
          boxShadow: 'none',
          borderBottomLeftRadius: '12px',
          borderBottomRightRadius: '12px',
        }}
        defaultExpanded={opened}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreRoundedIcon style={{ color: '#009F93' }} />}
          sx={{
            padding: '4px 16px',
            borderRadius: '12px',
            boxShadow: 'none',
            '&.Mui-expanded': {
              boxShadow: '0px 1px 0px #009F93',
            },
          }}
        >
          <div className="flex justify-between items-center gap-2 w-full">
            <div className="flex gap-1 items-center">
              {isInvited && (
                <Box
                  sx={{
                    width: 12,
                    height: 12,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: ' #FFE189',
                    borderRadius: '50%',
                  }}
                >
                  <CheckIcon sx={{ color: '#4CAF50', fontSize: 12 }} />
                </Box>
              )}
              <Typography
                className="!font-bold truncate text-green-main"
                sx={{
                  wordBreak: 'break-word',
                  overflowWrap: 'break-word',
                  whiteSpace: 'normal',
                  lineHeight: 1.2,
                  textAlign: 'left',
                }}
              >
                {name}
              </Typography>
              <IconButton size="medium" onClick={() => navigate(`/connections/${id}/edit`)}>
                <EditRoundedIcon style={{ color: '#009F93' }} />
              </IconButton>
            </div>

            <div className="flex gap-2">
              <IconButton size="medium" onClick={e => deleteConnection({ id, name }, false, e)}>
                <DeleteForeverRoundedIcon style={{ color: '#009F93' }} />
              </IconButton>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="pt-3">
            {!!occasions?.length &&
              occasions.map(({ id: occasionId, name: occasionName, date, ...occasion }) => (
                <div
                  key={occasionId}
                  className="flex flex-wrap sm:flex-nowrap justify-between items-start mb-3 sm:mb-1 min-h-[64px] text-green-main gap-1"
                >
                  <div className="flex flex-wrap sm:min-h-[40px] items-center">
                    <Typography
                      sx={{
                        fontWeight: 600,
                        wordBreak: 'break-word',
                        overflowWrap: 'break-word',
                        whiteSpace: 'normal',
                        lineHeight: 1.2,
                      }}
                      className="leading-none !mb-1 sm:mb-0 !mr-1"
                    >
                      {occasionName.toLowerCase() === 'no occasion' ? 'Gift without Occasion' : occasionName}
                    </Typography>
                    {date && (
                      <Typography className="leading-none italic !text-[12px]" sx={{ fontWeight: 600 }}>
                        {getDateLabel({ id: occasionId, name: occasionName, date, ...occasion })}
                      </Typography>
                    )}
                  </div>
                  <div className="shrink-0 ml-auto">
                    {occasionId === showBudgetField ? (
                      <form
                        onSubmit={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          openRecommends(id, occasionId);
                        }}
                      >
                        <div className="flex gap-1">
                          <Input
                            animatedLabel="Budget"
                            name="budget"
                            type="number"
                            autoComplete="off"
                            size="small"
                            className="w-32"
                            helperText={`Min ${Budget.MIN}, Max ${Budget.MAX}`}
                            FormHelperTextProps={{ style: { margin: '0 10px' } }}
                            ref={budgetInpRef}
                            value={budgetValue}
                            onChange={({ target }) => handleSetBudgetValue(target.value)}
                          />
                          <Button
                            type="submit"
                            className="!w-[40px] !min-w-[40px] !h-[40px] !p-1"
                            disabled={!isBudgetValid(budgetValue)}
                          >
                            <CheckRoundedIcon />
                          </Button>
                        </div>
                      </form>
                    ) : (
                      <Button
                        onClick={() => {
                          setShowBudgetField(occasionId);
                          setBudgetValue('');
                        }}
                        sx={{ color: '#009F93', textTransform: 'initial' }}
                      >
                        Budget
                      </Button>
                    )}
                  </div>
                </div>
              ))}
            <Button
              sx={{ p: 0, color: '#009F93', textTransform: 'initial', opacity: 0.5 }}
              variant="text"
              type="button"
              onClick={() => {
                navigate(`connections/${id}/occasion/add`);
              }}
            >
              + Add Occasion
            </Button>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ConnectionCard;
