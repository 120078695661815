import { API_BASE_URL } from '../../config/api';
import { fetchWithAuth, Auth } from '../../utils/fetch';

export interface NotificationSettings {
  globalOccasionsNotificationsEnabled: boolean;
  privateOccasionsNotificationsEnabled: boolean;
  emailNotificationsEnabled: boolean;
  pushNotificationsEnabled: boolean;
}

export const updateNotificationSettings = (auth: Auth, settings: NotificationSettings) =>
  fetchWithAuth<NotificationSettings>(auth, `${API_BASE_URL}/users/notifications`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: settings,
  });
