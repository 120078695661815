import React, { MouseEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Box } from '@mui/material';
import { AxiosError } from 'axios';
import { PersonAddAlt1Rounded as AddConnectionIcon, ShareRounded as ShareIcon } from '@mui/icons-material';

import waveBackground from '../../assets/wave.svg';
import Contact from '../../interfaces/Contact';
import useContacts from '../../data-hooks/contacts/useContacts';
import ConnectionCard from '../../components/ConnectionCard';
import CustomModal from '../../components/Modal';
import Button from '../../components/ui-kit/Button';
import BottomNavBar from '../../components/NavigationBar';
import { ReactComponent as LogoIcon } from '../../assets/present.svg';
import { useAuth } from '../../providers/auth';
import ActionButton from '../../components/ActionButton';
import useProfileSharing from '../../data-hooks/shareProfile/useProfileShare';
import NotificationPermissionPopup from '../../components/NotificationPermissionPopup';

const Connections: React.FC = () => {
  const navigate = useNavigate();
  const { data, handleDelete, handleFetch, handleUpdateProfileViaInvite } = useContacts({ fetchAll: true });
  const { profile } = useAuth();
  const [confirmDelete, setConfirmDelete] = useState<{ id: string; name: string } | null>(null);
  const [generalError, setGeneralError] = useState<string | string[]>('');
  const inviterId = localStorage.getItem('inviterId');
  const { share } = useProfileSharing(profile?.id);

  const deleteConnection = async (
    connection: Pick<Contact, 'id' | 'name'>,
    confirmed?: boolean,
    e?: MouseEvent<HTMLButtonElement>,
  ) => {
    e?.preventDefault();
    e?.stopPropagation();

    if (confirmed) {
      await handleDelete(connection.id);
      await handleFetch();
      setConfirmDelete(null);
      return;
    }

    setConfirmDelete(connection);
  };

  useEffect(() => {
    if (inviterId && inviterId !== profile?.id) {
      (async () => {
        try {
          const result = await handleUpdateProfileViaInvite(inviterId);

          if (result instanceof AxiosError) {
            const errorMessage = result.response?.data.message;

            if (errorMessage === 'This invitation has already been accepted') {
              setGeneralError(errorMessage);
              localStorage.removeItem('inviterId');
            }
          } else {
            localStorage.removeItem('inviterId');
            setGeneralError('');
            handleFetch();
          }
        } catch (err) {
          setGeneralError('Unexpected error occurred while updating profile. Please try again later.');
        }
      })();
    }
  }, [inviterId]);

  return (
    <div className="flex flex-col min-h-screen relative bg-green-main">
      <NotificationPermissionPopup />
      <div className="p-5 text-center self-center">
        <LogoIcon />
      </div>
      <div className="flex-grow flex flex-col">
        <div className="flex flex-col grow">
          <div
            className="h-[60px] text-white w-full md:h-[90px] xl:h-[150px]"
            style={{
              backgroundImage: `url(${waveBackground})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: '120%',
              backgroundPosition: 'top',
            }}
          />
          <div className="flex grow bg-green-light-bg pb-20">
            <div className="container min-h-full flex flex-col px-3">
              <div className="flex justify-center gap-16 mb-4">
                <ActionButton
                  action={() => share()}
                  label="Share my profile"
                  icon={<ShareIcon style={{ fontSize: '3.5rem', color: '#FFE189' }} />}
                />
                <ActionButton
                  action={() => navigate('/connections/new')}
                  label="Add connection"
                  icon={<AddConnectionIcon style={{ fontSize: '3.5rem', color: '#FFE189' }} />}
                />
              </div>

              <div className="flex justify-center mb-4 text-2xl font-medium text-[#004658]">My Connections</div>

              {!!generalError && (
                <div className="pb-5">
                  <Alert severity="error">{Array.isArray(generalError) ? generalError.at(-1) : generalError}</Alert>
                </div>
              )}

              <div>
                {!!data?.length &&
                  data.map(({ id, name, occasions, isInvited }, index) => (
                    <ConnectionCard
                      id={id}
                      name={name}
                      occasions={occasions}
                      isInvited={isInvited}
                      deleteConnection={deleteConnection}
                      key={id}
                      opened={index === 0}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>

        <CustomModal
          open={!!confirmDelete}
          onClose={() => setConfirmDelete(null)}
          titleColor="#2FAE94"
          title="Delete connection"
          withBorder
          footer={
            <div className="flex w-full relative">
              <Button
                type="button"
                variant="text"
                onClick={() => setConfirmDelete(null)}
                sx={{
                  color: '#2FAE94',
                  textTransform: 'initial',
                  width: '100%',
                  borderRadius: '0',
                  padding: 3,
                  '&:hover': {
                    backgroundColor: '#2FAE94',
                    color: '#fff',
                  },
                }}
              >
                No
              </Button>
              <Box
                sx={{
                  backgroundColor: '#F1F9FF',
                  position: 'absolute',
                  top: 0,
                  left: '50%',
                  width: '2px',
                  height: '100%',
                  transform: 'translateX(-1px)',
                }}
              />
              <Button
                type="button"
                variant="text"
                onClick={() => confirmDelete && deleteConnection(confirmDelete, true)}
                sx={{
                  color: '#2FAE94',
                  textTransform: 'initial',
                  width: '100%',
                  borderRadius: '0',
                  padding: 3,
                  '&:hover': {
                    backgroundColor: '#2FAE94',
                    color: '#fff',
                  },
                }}
              >
                Yes
              </Button>
            </div>
          }
        >
          <p className="text-green-main font-medium text-center px-5">Are you sure you want to delete connection?</p>
        </CustomModal>

        <BottomNavBar
          onMenuClick={() => navigate('/user-account')}
          onConnectionsClick={() => navigate('/')}
          onEventsClick={() => navigate('/events')}
        />
      </div>
    </div>
  );
};

export default Connections;
