import React, { useEffect, useState } from 'react';
import { FormikProvider, useFormik, FieldArray } from 'formik';
import * as Yup from 'yup';
import dayjs, { Dayjs } from 'dayjs';
import { Alert, CircularProgress } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import GoBackButton from '../../components/GoBackButton';
import { ReactComponent as LogoIcon } from '../../assets/present.svg';
import Button from '../../components/ui-kit/Button';
import useDropdowns from '../../data-hooks/profile-dropdowns/useDropdowns';
import useContacts from '../../data-hooks/contacts/useContacts';
import waveBackground from '../../assets/wave.svg';
import BottomNavBar from '../../components/NavigationBar';
import { OccasionPayload } from '../../data-hooks/auth/api';
import OccasionFormFields from '../../components/ProfileForm/OccasionFormFields';

interface FormOccasion {
  label: string;
  date: Dayjs | null;
}

interface FormValues {
  occasions: FormOccasion[];
}

const initialValues: FormValues = {
  occasions: [{ label: '', date: null }],
};

const Occasions: React.FC = () => {
  const navigate = useNavigate();
  const [generalError, setGeneralError] = useState<string>('');
  const { dropdowns, loading: loadingDropdowns } = useDropdowns();
  const { id } = useParams<{ id: string }>();
  const {
    selected: connection,
    loading: loadingConnection,
    handleUpdateContactsOccasion,
  } = useContacts({ fetchAll: false, fetchById: id });

  const formikState = useFormik<FormValues>({
    initialValues,
    validationSchema: Yup.object({
      occasions: Yup.array().of(
        Yup.object().shape({
          label: Yup.string().required('Occasion Type is required'),
          date: Yup.date().nullable().required('Occasion Date is required'),
        }),
      ),
    }),
    onSubmit: async val => {
      try {
        const payload: OccasionPayload = {
          occasions: val.occasions.map(item => ({
            name: item.label,
            date: item.date ? item.date.toISOString() : '',
          })),
        };

        if (id) {
          await handleUpdateContactsOccasion(id, payload);
          navigate('/connections');
        }
      } catch (error) {
        setGeneralError('Something went wrong. Please try again.');
      }
    },
  });

  const { setValues } = formikState;

  useEffect(() => {
    if (connection?.occasions && connection.occasions.length > 0) {
      const formattedOccasions = connection.occasions.map(occasion => ({
        label: occasion.name,
        date: occasion.date ? dayjs(occasion.date) : null,
      }));
      setValues({ occasions: formattedOccasions });
    }
  }, [connection, setValues]);

  if (loadingDropdowns || loadingConnection) {
    return (
      <div className="flex justify-center items-center h-[40vh]">
        <CircularProgress size={40} />
      </div>
    );
  }

  return (
    <div className="flex flex-col min-h-screen bg-green-main relative">
      <div className="p-5 text-center self-center relative">
        <div className="absolute top-8 -left-28 md:-left-40">
          <GoBackButton />
        </div>
        <div className="justify-center">
          <LogoIcon />
        </div>
      </div>
      <div
        className="h-[60px] text-white w-full md:h-[90px] xl:h-[150px]"
        style={{
          backgroundImage: `url(${waveBackground})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '120%',
          backgroundPosition: 'top',
        }}
      />
      <div className="flex grow bg-green-light-bg pb-20">
        <div className="container pb-10 flex flex-col gap-12">
          <FormikProvider value={formikState}>
            <form onSubmit={formikState.handleSubmit} className="flex flex-col gap-3 w-full">
              <h3 className="text-center text-green-main font-bold text-2xl mb-4">
                Enter an occasion and date for which you would like to create gifts
              </h3>
              {!!generalError && <Alert severity="error">{generalError}</Alert>}
              <FieldArray
                name="occasions"
                render={arrayHelpers => (
                  <div className="flex flex-col gap-16">
                    {formikState.values.occasions.map((occasion, index) => (
                      <OccasionFormFields
                        // eslint-disable-next-line react/no-array-index-key
                        key={`Ocassion: ${occasion.label} + ${occasion.date} + ${index}`}
                        label={occasion.label}
                        date={occasion.date}
                        index={index}
                        arrayHelpers={arrayHelpers}
                        occasionTypes={dropdowns.occasions}
                        selectedOccasions={formikState.values.occasions}
                        touched={formikState.touched.occasions?.[index] || {}}
                        errors={formikState.errors.occasions?.[index] || {}}
                        showDelete={formikState.values.occasions.length > 1}
                        handleChange={formikState.handleChange}
                        handleBlur={formikState.handleBlur}
                        handleDateChange={value => formikState.setFieldValue(`occasions.${index}.date`, value)}
                        formikState={formikState}
                      />
                    ))}
                    <Button
                      type="button"
                      variant="text"
                      onClick={() => arrayHelpers.push({ label: '', date: null })}
                      disabled={formikState.values.occasions.some(({ label, date }) => !label || !date)}
                      sx={{ alignSelf: 'flex-start', textTransform: 'initial', color: '#009F93' }}
                    >
                      + More
                    </Button>
                  </div>
                )}
              />
              <Button type="submit" color="secondary" disabled={!formikState.isValid}>
                Save
              </Button>
            </form>
          </FormikProvider>

          <BottomNavBar
            onMenuClick={() => navigate('/user-account')}
            onConnectionsClick={() => navigate('/')}
            onEventsClick={() => navigate('/events')}
          />
        </div>
      </div>
    </div>
  );
};

export default Occasions;
