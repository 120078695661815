import React, { useEffect, useState } from 'react';
import { BottomNavigation, BottomNavigationAction, Box, Modal, Paper } from '@mui/material';
import { PersonAddAlt1Rounded as AddConnectionIcon, ShareRounded as ShareIcon } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import GroupIcon from '@mui/icons-material/Group';
import EventIcon from '@mui/icons-material/Event';
import { useMatches, useNavigate } from 'react-router-dom';
import ActionButton from '../ActionButton';
import { useAuth } from '../../providers/auth';
import useProfileSharing from '../../data-hooks/shareProfile/useProfileShare';

const COMMON_ICON_COLOR = '#FFE189';

interface BottomNavBarProps {
  onMenuClick: () => void;
  onConnectionsClick: () => void;
  onEventsClick: () => void;
}

const routesMap: { [key: string]: number } = {
  '/user-account': 0,
  '/': 2,
  '/events': 3,
} as const;

const BottomNavBar: React.FC<BottomNavBarProps> = ({ onMenuClick, onConnectionsClick, onEventsClick }: any) => {
  const matches = useMatches();
  const [value, setValue] = useState<number | null>(null);
  const navigate = useNavigate();
  const [isWidgetOpen, setIsWidgetOpen] = useState(false);
  const { profile } = useAuth();
  const { share } = useProfileSharing(profile?.id);

  useEffect(() => {
    if (matches.length > 0) {
      const currentPath = matches[matches.length - 1].pathname;

      setValue(routesMap[currentPath]);
    }
  }, [matches]);

  return (
    <>
      <Modal open={isWidgetOpen} onClose={() => setIsWidgetOpen(false)}>
        <div className="flex justify-center gap-16 fixed bottom-16 left-0 right-0">
          <ActionButton
            action={() => share()}
            label="Share my profile"
            icon={<ShareIcon style={{ fontSize: '3.5rem', color: '#FFE189' }} />}
          />
          <ActionButton
            action={() => navigate('/connections/new')}
            label="Add connection"
            icon={<AddConnectionIcon style={{ fontSize: '3.5rem', color: '#FFE189' }} />}
          />
        </div>
      </Modal>

      <Box>
        <Paper
          sx={{
            borderRadius: '24px 24px 0 0',
            overflow: 'hidden',
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: '1000',
          }}
          elevation={3}
        >
          <BottomNavigation
            value={value}
            showLabels={!!true}
            onChange={(_, newValue) => setValue(newValue)}
            sx={{
              backgroundColor: '#009F93',
              '& .MuiBottomNavigationAction-root': {
                color: COMMON_ICON_COLOR,
              },
              '& .Mui-selected': {
                color: '#FFFFFF !important',
              },
            }}
          >
            <BottomNavigationAction
              label="Menu"
              icon={<MenuIcon />}
              onClick={onMenuClick}
              sx={{ color: COMMON_ICON_COLOR }}
            />
            <BottomNavigationAction
              label="Add"
              icon={<PersonAddIcon />}
              onClick={() => setIsWidgetOpen(true)}
              sx={{ color: COMMON_ICON_COLOR }}
            />
            <BottomNavigationAction
              label="Connections"
              icon={<GroupIcon />}
              onClick={onConnectionsClick}
              sx={{ color: COMMON_ICON_COLOR }}
            />
            <BottomNavigationAction
              label="Events"
              icon={<EventIcon />}
              onClick={onEventsClick}
              sx={{ color: COMMON_ICON_COLOR }}
            />
          </BottomNavigation>
        </Paper>
      </Box>
    </>
  );
};

export default BottomNavBar;
