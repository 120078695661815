import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import ShareButton from '../../../components/ShareButton';

interface FindUsSectionProps {
  color?: string;
}

const FindUsSection: React.FC<FindUsSectionProps> = ({ color = '#000000de' }) => {
  const handleFacebookClick = () => {
    window.open('https://www.facebook.com/profile.php?id=61556705795264', '_blank');
  };

  const handleInstagramClick = () => {
    window.open('https://www.instagram.com/offra___/profilecard/?igsh=MWg3YndhcDM4ODY0aw%3D%3D', '_blank');
  };

  return (
    <Box display="flex" flexDirection="column" gap={4} sx={{ alignSelf: 'center' }}>
      <ShareButton
        variant="contained"
        color="secondary"
        urlPath={`${window.location.origin}/login`}
        sx={{ textTransform: 'initial' }}
      >
        Share offra
      </ShareButton>

      <Box display="flex" flexDirection="column" gap={2}>
        <Typography variant="body1" fontWeight="bold" sx={{ color }}>
          Find us:
        </Typography>
        <Box display="flex" alignItems="center" gap={3} paddingLeft={2}>
          <IconButton aria-label="Facebook" onClick={handleFacebookClick}>
            <FacebookIcon sx={{ color, fontSize: 30, opacity: 0.7 }} />
          </IconButton>
          <IconButton aria-label="Instagram" onClick={handleInstagramClick}>
            <InstagramIcon sx={{ color, fontSize: 30, opacity: 0.7 }} />
          </IconButton>
          <Typography sx={{ fontSize: 12, fontWeight: 600, color }}>
            As an Amazon Associate, we earn from qualifying purchases.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default FindUsSection;
