import React from 'react';
import RefreshIcon from '@mui/icons-material/Refresh'; // MUI icon for refresh
import { Button } from '@mui/material';

interface RefreshSuggestionsButtonProps {
  onClick: () => void;
}

const RefreshSuggestions: React.FC<RefreshSuggestionsButtonProps> = ({ onClick }) => {
  return (
    <Button
      onClick={onClick}
      variant="text"
      sx={{ color: '#009F93', display: 'flex', alignItems: 'center', gap: 1, padding: 0 }}
      style={{ textTransform: 'none', fontSize: '14px', fontWeight: 'bold' }}
    >
      <RefreshIcon fontSize="small" />
      More suggestions
    </Button>
  );
};

export default RefreshSuggestions;
