import React, { useState } from 'react';
import { FormikErrors, FormikTouched } from 'formik';
import { Alert, CircularProgress, IconButton, InputAdornment } from '@mui/material';
import { Link } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import Input from '../ui-kit/Input';
import Button from '../ui-kit/Button';

export interface FormValues {
  email: string;
  password: string;
}

export interface Props {
  values: FormValues;
  touched: FormikTouched<FormValues>;
  errors: FormikErrors<FormValues>;
  loading: boolean;
  generalError: string | string[];
  handleChange(e: React.ChangeEvent<any>): void;
  handleBlur(e: React.FocusEvent<any, Element>): void;
  handleSubmit(e?: React.FormEvent<HTMLFormElement>): void;
}

const LoginForm = ({
  values,
  errors,
  touched,
  loading,
  generalError,
  handleChange,
  handleBlur,
  handleSubmit,
}: Props) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(prevShowPassword => !prevShowPassword);
  };

  return (
    <form className="flex flex-col gap-2 w-full" onSubmit={handleSubmit}>
      {!!generalError && (
        <div className="pb-5">
          <Alert severity="error">{Array.isArray(generalError) ? generalError.at(-1) : generalError}</Alert>
        </div>
      )}
      <div className="flex flex-col w-full gap-5">
        <Input
          placeholder="Email"
          name="email"
          autoComplete="off"
          value={values.email}
          error={!!(touched?.email && errors?.email)}
          helperText={touched?.email && errors?.email ? errors.email : null}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <Input
          placeholder="Password"
          name="password"
          type={showPassword ? 'text' : 'password'}
          autoComplete="off"
          value={values.password}
          error={!!(touched?.password && errors?.password)}
          helperText={touched?.password && errors?.password ? errors.password : null}
          onChange={handleChange}
          onBlur={handleBlur}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" sx={{ mr: 1 }}>
                <IconButton aria-label="toggle password visibility" onClick={handleTogglePasswordVisibility} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>

      <Link to="/forgot-password" className="px-4 text-green-main mb-7 font-semibold">
        Forgot password?
      </Link>

      <div className="flex justify-center">
        <Button
          type="submit"
          color="secondary"
          disabled={!!(touched?.email && errors?.email) || !!(touched?.password && errors?.password) || loading}
          startIcon={loading ? <CircularProgress size={18} /> : null}
          sx={{ width: '100%', textTransform: 'initial' }}
        >
          Sign in
        </Button>
      </div>
    </form>
  );
};

export default LoginForm;
