import React, { useEffect } from 'react';
import useNotificationSettings from '../../data-hooks/push-notifications/useNotificationSettings';
import { fetchWithAuth } from '../../utils/fetch';
import { API_BASE_URL } from '../../config/api';
import useAuth from '../../data-hooks/auth/useAuth';

const NotificationPermissionPopup: React.FC = () => {
  const { handleUpdate } = useNotificationSettings();
  const auth = useAuth();

  useEffect(() => {
    const registerServiceWorkerAndRequestPermission = async () => {
      if ('serviceWorker' in navigator && 'PushManager' in window) {
        try {
          const registration = await navigator.serviceWorker.register('/sw.js');

          const permission = await Notification.requestPermission();
          if (permission === 'granted') {
            handleUpdate({
              globalOccasionsNotificationsEnabled: true,
              privateOccasionsNotificationsEnabled: true,
              emailNotificationsEnabled: true,
              pushNotificationsEnabled: true,
            });

            const vapidKey = process.env.REACT_APP_VAPID_PUBLIC_KEY;
            const subscription = await registration.pushManager.subscribe({
              userVisibleOnly: true,
              applicationServerKey: vapidKey,
            });
            await fetchWithAuth(auth, `${API_BASE_URL}/users/push-notifications/subscribe`, {
              method: 'POST',
              data: subscription,
              headers: {
                'content-type': 'application/json',
              },
            });
          } else {
            handleUpdate({
              globalOccasionsNotificationsEnabled: true,
              privateOccasionsNotificationsEnabled: true,
              emailNotificationsEnabled: true,
              pushNotificationsEnabled: false,
            });
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Failed to register Service Worker or update preferences:', error);
        }
      }
    };

    registerServiceWorkerAndRequestPermission();
  }, []);

  return null;
};

export default NotificationPermissionPopup;
