import React, { useMemo, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { Box, Button, ListItem, ListItemText } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import LockIcon from '@mui/icons-material/Lock';
import HelpIcon from '@mui/icons-material/Help';
import PhoneIcon from '@mui/icons-material/Phone';
import LogoutIcon from '@mui/icons-material/Logout';
import HandshakeIcon from '@mui/icons-material/Handshake';
import CustomModal from '../../components/Modal';

import { useAuth } from '../../providers/auth';

import waveBackground from '../../assets/wave.svg';
import AccountInfo from './AccountInfo';
import CustomAccordion from './CustomAccordion';
import NotificationsAccordion from './NotificationAccordion';
import FAQDialog from './FAQDialog';
import { ReactComponent as LogoIcon } from '../../assets/present.svg';
import BottomNavBar from '../../components/NavigationBar';
import FindUsSection from './FindUsSection';

declare global {
  interface Window {
    Cookiebot: {
      renew: () => void;
      show: () => void;
    };
  }
}
const UserAccount = () => {
  const { profile, handleLogout, handleDeleteAccount } = useAuth();
  const navigate = useNavigate();
  const [FAQModalOpened, setFAQModalOpened] = useState(false);
  const [activeAccordion, setActiveAccordion] = useState<string | null>(null);
  const [deleteModalOpened, setDeleteModalOpened] = useState(false);

  const settings = useMemo(
    () => ({
      emailNotificationsEnabled: profile?.emailNotificationsEnabled || false,
      globalOccasionsNotificationsEnabled: profile?.globalOccasionsNotificationsEnabled || false,
      privateOccasionsNotificationsEnabled: profile?.privateOccasionsNotificationsEnabled || false,
      pushNotificationsEnabled: profile?.pushNotificationsEnabled || false,
    }),
    [profile],
  );

  const toggleAccordion = (accordion: string) => {
    setActiveAccordion(prev => (prev === accordion ? null : accordion));
  };

  const deleteAccount = () => {
    const token = localStorage.getItem('accessToken');

    if (token) {
      handleDeleteAccount(token);
      setDeleteModalOpened(false);
    }
  };

  const handleDeleteClick = () => {
    setDeleteModalOpened(true);
  };

  const handlePolicyClick = () => {
    window.open('/policy', '_blank');
  };

  const logout = async () => {
    await handleLogout();
    navigate('/login');
    window.location.reload(); // this logic is neccessary for FB auth to function properly after logout
  };

  const handleEditProfile = () => {
    navigate(`/profile/${profile?.id}`);
  };

  const handleChangePassword = async () => {
    await handleLogout();
    navigate('/forgot-password');
  };

  const handleContactClick = () => {
    window.location.href = 'mailto:contact@offra.eu';
  };

  const handleBecomePartner = () => {
    window.open('https://www.offraworld.com', '_blank');
  };

  const manageCookies = () => {
    if (window.Cookiebot) {
      window.Cookiebot.show();
    } else {
      // eslint-disable-next-line no-console
      console.error('Cookiebot is not initialized');
    }
  };

  const settingsItems = [
    { label: 'Change Password', onClick: handleChangePassword },
    { label: 'Delete Account', onClick: handleDeleteClick },
  ];

  const preferencesItems = [
    { label: 'Cookies Management', onClick: manageCookies },
    { label: 'Terms and Conditions', onClick: handlePolicyClick },
  ];

  if (!profile) {
    return null;
  }

  return (
    <div className="flex flex-col min-h-screen bg-green-main relative">
      <div className="p-5 text-center self-center">
        <LogoIcon />
      </div>
      <div className="flex flex-col grow">
        <div
          className="h-[60px] text-white w-full md:h-[90px] xl:h-[150px]"
          style={{
            backgroundImage: `url(${waveBackground})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '120%',
            backgroundPosition: 'top',
          }}
        />
        <div className="flex grow bg-green-light-bg pb-20 flex-col px-5 md:px-10 xl:px-20 gap-14">
          <div className="container min-h-full flex flex-col">
            <AccountInfo profile={profile} onEdit={handleEditProfile} />

            <div className="flex flex-col w-full max-w-xs mt-6">
              <CustomAccordion
                title="Settings"
                icon={SettingsIcon}
                items={settingsItems}
                isOpen={activeAccordion === 'settings'}
                onToggle={() => toggleAccordion('settings')}
              />
              <CustomAccordion
                title="Preferences"
                icon={LockIcon}
                items={preferencesItems}
                isOpen={activeAccordion === 'preferences'}
                onToggle={() => toggleAccordion('preferences')}
              />

              <NotificationsAccordion
                isOpen={activeAccordion === 'notifications'}
                onToggle={() => toggleAccordion('notifications')}
                settings={settings}
              />

              <ListItem
                button
                onClick={handleBecomePartner}
                sx={{ padding: 0, '&:hover': { backgroundColor: 'transparent' } }}
              >
                <HandshakeIcon sx={{ marginRight: 4, width: '20px', height: '20px' }} />
                <ListItemText
                  primary="Become a partner"
                  sx={{ paddingY: 1 }}
                  primaryTypographyProps={{ fontWeight: 600 }}
                />
              </ListItem>

              <ListItem
                button
                sx={{ padding: 0, '&:hover': { backgroundColor: 'transparent' } }}
                onClick={() => setFAQModalOpened(true)}
              >
                <HelpIcon sx={{ marginRight: 4, width: '20px', height: '20px' }} />
                <ListItemText primary="About / FAQ" sx={{ paddingY: 1 }} primaryTypographyProps={{ fontWeight: 600 }} />
              </ListItem>

              <ListItem
                button
                sx={{ padding: 0, '&:hover': { backgroundColor: 'transparent' } }}
                onClick={handleContactClick}
              >
                <PhoneIcon sx={{ marginRight: 4, width: '20px', height: '20px' }} />
                <ListItemText
                  primary="Help / Contact"
                  sx={{ paddingY: 1 }}
                  primaryTypographyProps={{ fontWeight: 600 }}
                />
              </ListItem>

              <ListItem button onClick={logout} sx={{ padding: 0, '&:hover': { backgroundColor: 'transparent' } }}>
                <LogoutIcon sx={{ marginRight: 4, width: '20px', height: '20px' }} />
                <ListItemText primary="Logout" sx={{ paddingY: 1 }} primaryTypographyProps={{ fontWeight: 600 }} />
              </ListItem>
            </div>
          </div>
          <FindUsSection />
        </div>

        <FAQDialog open={FAQModalOpened} onClose={() => setFAQModalOpened(false)} />

        <CustomModal
          open={!!deleteModalOpened}
          onClose={() => setDeleteModalOpened(false)}
          titleColor="#2FAE94"
          title="Delete connection"
          withBorder
          footer={
            <div className="flex w-full relative">
              <Button
                type="button"
                variant="text"
                onClick={() => setDeleteModalOpened(false)}
                sx={{
                  color: '#2FAE94',
                  textTransform: 'initial',
                  width: '100%',
                  borderRadius: '0',
                  padding: 3,
                  '&:hover': {
                    backgroundColor: '#2FAE94',
                    color: '#fff',
                  },
                }}
              >
                No
              </Button>
              <Box
                sx={{
                  backgroundColor: '#F1F9FF',
                  position: 'absolute',
                  top: 0,
                  left: '50%',
                  width: '2px',
                  height: '100%',
                  transform: 'translateX(-1px)',
                }}
              />
              <Button
                type="button"
                variant="text"
                onClick={deleteAccount}
                sx={{
                  color: '#2FAE94',
                  textTransform: 'initial',
                  width: '100%',
                  borderRadius: '0',
                  padding: 3,
                  '&:hover': {
                    backgroundColor: '#2FAE94',
                    color: '#fff',
                  },
                }}
              >
                Yes
              </Button>
            </div>
          }
        >
          <p className="text-green-main font-medium text-center px-5">Are you sure you want to delete your account?</p>
        </CustomModal>

        <BottomNavBar
          onMenuClick={() => navigate('/user-account')}
          onConnectionsClick={() => navigate('/')}
          onEventsClick={() => navigate('/events')}
        />
      </div>
    </div>
  );
};

export default UserAccount;
