import { CodeResponse, GoogleOAuthProvider } from '@react-oauth/google';
import React from 'react';
import CustomGoogleButton from '../../CustomGoogleButton';
import FacebookLoginButton from '../../FacebookAuthButton';
import { AuthError } from '../../../utils/fetch';
import { useAuth } from '../../../providers/auth';

const SocialLogin: React.FC<{ setGeneralError: React.Dispatch<React.SetStateAction<string | string[]>> }> = ({
  setGeneralError,
}) => {
  const { handleGoogleLogin } = useAuth();

  const googleLoginCallback = async (response: CodeResponse) => {
    try {
      await handleGoogleLogin(response.code);
    } catch (error) {
      if (error instanceof AuthError) {
        setGeneralError('Google authentication failed.');
      } else {
        setGeneralError('Something went wrong during Google login.');
      }
    }
  };
  return (
    <>
      <div className="relative py-4 text-center text-blue-main font-semibold before:absolute before:content-[''] before:w-5 before:h-[1px] before:bg-blue-main before:-left-6 after:absolute after:content-[''] after:w-5 after:h-[1px] after:bg-blue-main after:-right-6 before:top-1/2 after:top-1/2">
        Or Sign in with
      </div>
      <div className="flex gap-4 mb-12">
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}>
          <CustomGoogleButton loginCallback={googleLoginCallback} setGeneralError={setGeneralError} />
        </GoogleOAuthProvider>
        <FacebookLoginButton loginCallback={() => {}} setGeneralError={setGeneralError} />
      </div>
    </>
  );
};

export default SocialLogin;
