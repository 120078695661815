import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { AxiosError } from 'axios';
import { Box, Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import { Occasion, ProfileUpdateDto } from '../../interfaces/Profile';
import { useAuth } from '../../providers/auth';
import useDropdowns from '../../data-hooks/profile-dropdowns/useDropdowns';
import ProfileForm, { profileFormValidators, ProfileValues } from '../../components/ProfileForm';
import ShareButton from '../../components/ShareButton';
import Modal from '../../components/Modal';
import Logo from '../../components/ui-kit/Logo';
import GoBackButton from '../../components/GoBackButton';

const initialValues: ProfileValues = {
  name: '',
  gender: '',
  birthday: null,
  country: '',
  hobbiesAndInterests: [],
  customHobbiesAndInterests: [],
  occasions: [
    {
      label: '',
      date: null,
    },
  ],
};

const validationSchema = Yup.object<ProfileValues>().shape(profileFormValidators);

const Profile: React.FC = () => {
  const [generalError, setGeneralError] = useState<string>('');
  const { profile, loading, handleUpdateProfile } = useAuth();
  const { dropdowns, setDropdowns } = useDropdowns();
  const navigate = useNavigate();
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const { id } = useParams<{ id?: string }>();
  const url = `${window.location.origin}/share-profile/${id}`;

  const formikState = useFormik<ProfileValues>({
    initialValues,
    validationSchema,
    validateOnChange: true,
    onSubmit: async (val: ProfileValues) => {
      const bYear = val.birthday?.get('year');
      const bMonth = (val.birthday?.get('month') || 0) + 1;
      const bDate = val.birthday?.get('date') || 0;
      const existingOccasions = profile?.occasions || [];

      const newOccasions = val.occasions.reduce<Pick<Occasion, 'name' | 'date'>[]>(
        (res: Pick<Occasion, 'name' | 'date'>[], { label, date }) => {
          if (label && date) {
            const oYear = date.get('year');
            const oMonth = date.get('month') + 1;
            const oDate = date.get('date');
            return [
              ...res,
              {
                name: label,
                date: `${oYear}-${oMonth >= 10 ? oMonth : `0${oMonth}`}-${oDate >= 10 ? oDate : `0${oDate}`}T00:00:00.000Z`,
              },
            ];
          }
          return res;
        },
        [],
      );

      const mergedOccasions = [
        ...existingOccasions,
        ...newOccasions.filter(
          newOccasion =>
            !existingOccasions.some(
              existing => existing.name === newOccasion.name && existing.date === newOccasion.date,
            ),
        ),
      ];

      const data: ProfileUpdateDto = {
        ...val,
        id: profile!.id,
        email: profile!.email,
        birthday: `${bYear}-${bMonth >= 10 ? bMonth : `0${bMonth}`}-${bDate >= 10 ? bDate : `0${bDate}`}T00:00:00.000Z`,
        occasions: mergedOccasions,
      };

      if (!data.customHobbiesAndInterests?.length) {
        delete data.customHobbiesAndInterests;
      }

      if (!data.occasions?.length) {
        delete data.occasions;
      }

      try {
        await handleUpdateProfile(data);
        return setIsModalOpened(true);
      } catch (err) {
        window.scrollTo(0, 0);
        if (err instanceof AxiosError && err.response?.data?.message) {
          return setGeneralError(
            Array.isArray(err.response.data.message) ? err.response.data.message.at(-1) : err.response.data.message,
          );
        }
        return setGeneralError('Something went wrong. Try again later, please.');
      }
    },
  });

  const { values, errors, touched, isValid, submitCount, handleSubmit, handleChange, handleBlur, setValues } =
    formikState;

  useEffect(() => {
    if (profile) {
      setValues({
        name: profile.name || '',
        gender: profile.gender || '',
        birthday: profile.birthday ? dayjs(profile.birthday) : null,
        country: profile.country || '',
        hobbiesAndInterests: profile.hobbiesAndInterests || [],
        customHobbiesAndInterests: profile.customHobbiesAndInterests || [],
        occasions: [],
      });
    }
  }, [profile, setValues]);

  return (
    <div className="flex flex-col min-h-screen bg-yellow-main relative">
      <div className="p-5 text-center self-center">
        <Logo color="primary" classnames="w-[320px]" />
      </div>

      <div className="flex flex-col grow">
        <div className="container pb-10 ">
          {id && <GoBackButton />}

          <div className="flex flex-col gap-12">
            <div>
              <ProfileForm
                title={id ? 'Update your profile' : 'Create a Profile'}
                showOccasionFields={false}
                values={values}
                errors={errors}
                touched={touched}
                loading={loading}
                dropdowns={dropdowns}
                isValid={submitCount === 0 || isValid}
                formikState={formikState}
                generalError={generalError}
                handleSubmit={handleSubmit}
                handleChange={handleChange}
                handleBlur={handleBlur}
                setDropdowns={setDropdowns}
              />

              {profile?.id && (
                <Button
                  sx={{ p: 0, color: '#009F93', textTransform: 'initial', opacity: 0.5 }}
                  variant="text"
                  type="button"
                  onClick={() => {
                    navigate(`/profile/${profile?.id}/occasion/add`);
                  }}
                >
                  + Add Occasion
                </Button>
              )}
            </div>

            <div className="flex flex-col gap-4">
              <p className="text-center mb-5 text-green-main font-medium text-xl">
                You can also invite them to connect with you on OFFRA.
              </p>
              <ShareButton
                type="button"
                color="secondary"
                sx={{ textTransform: 'initial' }}
                urlPath={id ? url : undefined}
              >
                Share my profile
              </ShareButton>
            </div>
          </div>

          <Modal
            open={isModalOpened}
            onClose={() => setIsModalOpened(false)}
            title="Welcome to Offra!"
            titleColor="#2FAE94"
            footer={
              <Box sx={{ paddingBottom: 3 }}>
                <Button
                  type="button"
                  variant="text"
                  onClick={() => {
                    navigate('/');
                  }}
                  sx={{ color: '#2FAE94', textTransform: 'initial' }}
                >
                  Next
                </Button>
              </Box>
            }
          >
            <p className="text-green-main text-center">
              {id ? 'Your profile was successfully updated' : 'Your profile was successfully created'}
            </p>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Profile;
