import React from 'react';
import { Avatar, Box, Button } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import EditIcon from '@mui/icons-material/Edit';
import dayjs from 'dayjs';
import Profile from '../../../interfaces/Profile';

interface AccountInfoProps {
  profile: Profile;
  onEdit: any;
}

const AccountInfo: React.FC<AccountInfoProps> = ({ profile, onEdit }) => {
  return (
    <div className="flex gap-4 items-center">
      <Avatar sx={{ width: 80, height: 80, bgcolor: '#004658', borderRadius: 4, marginTop: -2 }}>
        <PersonIcon sx={{ fontSize: 40, color: '#FFE189' }} />
      </Avatar>
      <div className="flex flex-col gap-1">
        <div className="flex flex-col">
          <p className="font-semibold">{profile.name}</p>
          <p>{dayjs(profile.birthday).format('DD/MM/YYYY')}</p>
        </div>
        <Box className="flex gap-2 items-center -mt-2 cursor-pointer" onClick={onEdit}>
          <Button
            variant="text"
            sx={{
              alignSelf: 'flex-start',
              justifySelf: 'flex-start',
              padding: 0,
              color: 'black',
              textTransform: 'initial',
              fontWeight: 400,
              fontSize: '12px',
            }}
          >
            Edit my profile
          </Button>
          <EditIcon sx={{ width: '16px', height: '16px', cursor: 'pointer' }} />
        </Box>
      </div>
    </div>
  );
};

export default AccountInfo;
