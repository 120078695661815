import React, { useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import ShareRoundedIcon from '@mui/icons-material/ShareRounded';

import Button, { ButtonProps } from '../ui-kit/Button';
import { useAuth } from '../../providers/auth';

interface ShareButtonProps extends ButtonProps {
  urlPath?: string;
}

const ShareButton: React.FC<ShareButtonProps> = (props: ShareButtonProps) => {
  const { color = 'secondary', startIcon = <ShareRoundedIcon />, urlPath, ...btnProps } = props;
  const { profile } = useAuth();
  const [showCopiedMsg, setShowCopiedMsg] = useState(false);

  const text = `Need help finding the right gifts? 🎁 \n\n${profile?.name} is already a member and wants you to be a part of OFFRA! 👋 \nCreate your free account now 🎉\n\n`;

  const url = urlPath || `${window.location.origin}`;

  const getShareData = (): { text?: string; url: string } | undefined => {
    let shareData;
    if (navigator.canShare({ text, url })) {
      shareData = { text, url };
    } else if (navigator.canShare({ url })) {
      shareData = { url };
    }

    return shareData;
  };

  const canShare = () => typeof navigator.canShare === 'function' && !!getShareData();

  const copy = async () => {
    try {
      await navigator.clipboard.writeText(`${text} ${url}`);
      setShowCopiedMsg(true);
    } catch (err) {
      /* eslint-disable-next-line no-console */
      console.log(err);
    }
  };

  const share = async () => {
    try {
      if (canShare()) {
        await navigator.share(getShareData());
      } else {
        await copy();
      }
    } catch (err) {
      /* eslint-disable-next-line no-console */
      console.log(err);
      await copy();
    }
  };

  return (
    <>
      <Snackbar
        key="copied_to_clipboard"
        open={showCopiedMsg}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={() => setShowCopiedMsg(false)}
      >
        <Alert severity="success">Copied to clipboard</Alert>
      </Snackbar>
      <Button {...btnProps} startIcon={startIcon} color={color} onClick={() => share()} />
    </>
  );
};

export default ShareButton;
