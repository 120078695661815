import React from 'react';
import { Paper, Typography, Skeleton, Button } from '@mui/material';

interface RecommendationCardProps {
  id: string;
  title: string;
  originalTitle: string | undefined;
  image: string;
  link: string;
  price?: string;
  reason?: string;
  loading: boolean;
  formatPrice: (price: string) => string;
}

const RecommendationCard: React.FC<RecommendationCardProps> = ({
  title,
  originalTitle,
  image,
  link,
  price,
  reason,
  loading,
  formatPrice,
}) => {
  return (
    <Paper className="mb-3">
      <a href={link} target="_blank" rel="noreferrer">
        <div className="flex gap-2">
          <div className="basis-2/5 shrink-1 relative flex items-center justify-center">
            <img
              src={image}
              alt={title}
              className="absolute object-contain top-0 left-0 w-full h-full max-w-40 max-h-40"
            />
          </div>
          <div className="w-full flex flex-col basis-3/5 justify-between bg-[#E4F5F8] p-2">
            <div>
              <Typography className="!font-bold !text-[14px] !leading-tight !break-words">
                {title || originalTitle}
              </Typography>
              {!reason && loading && (
                <div className="w-full py-2">
                  <Skeleton variant="text" width="full" />
                  <Skeleton variant="text" width="full" />
                </div>
              )}
              {!!reason && !loading && (
                <div className="w-full py-1">
                  <Typography className="!text-[14px] !leading-tight !break-words">{reason}</Typography>
                </div>
              )}
            </div>
            <div className="flex items-center justify-between gap-5 pt-4">
              <Typography>{!!price && formatPrice(price)}</Typography>
              <Button>Open</Button>
            </div>
          </div>
        </div>
      </a>
    </Paper>
  );
};

export default RecommendationCard;
