import React from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

interface ModalProps {
  open: boolean;
  onClose: () => void;
  title?: string;
  children: React.ReactNode;
  footer?: React.ReactNode;
  titleColor?: string;
  withBorder?: boolean;
}

const Modal: React.FC<ModalProps> = ({ open, onClose, title, children, footer, titleColor, withBorder = false }) => (
  <Dialog
    open={open}
    onClose={onClose}
    disableScrollLock
    sx={{
      padding: 0,
      '& .MuiPaper-root': { borderRadius: '20px' },
    }}
  >
    <Box sx={{ display: 'flex', flexDirection: 'column', paddingTop: 5 }}>
      {title && <DialogTitle sx={{ color: titleColor, textAlign: 'center', fontWeight: '600' }}>{title}</DialogTitle>}
      <DialogContent sx={{ display: 'flex' }}>{children}</DialogContent>
      {footer && (
        <DialogActions
          sx={{
            width: '100%',
            borderTop: withBorder ? '2px solid #F1F9FF' : 'none',
            padding: 0,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {footer}
        </DialogActions>
      )}
    </Box>
  </Dialog>
);

export default Modal;
