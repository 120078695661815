import React from 'react';

import { CodeResponse, useGoogleLogin } from '@react-oauth/google';
import { ReactComponent as GoogleIcon } from '../../assets/google.svg';
import Button from '../ui-kit/Button';

interface CustomGoogleButtonProps {
  loginCallback: (response: CodeResponse) => void;
  setGeneralError: React.Dispatch<React.SetStateAction<string | string[]>>;
}

const CustomGoogleButton: React.FC<CustomGoogleButtonProps> = ({ loginCallback, setGeneralError }) => {
  const onLogin = useGoogleLogin({
    onSuccess: codeResponse => {
      loginCallback(codeResponse);
    },
    onError: () => {
      setGeneralError('Error during Google login');
    },
    flow: 'auth-code',
  });

  return (
    <Button
      onClick={() => onLogin()}
      sx={{
        backgroundColor: '#fff',
        border: '1px solid #d9d9d9',
        borderRadius: '50%',
        minWidth: '50px',
        minHeight: '50px',
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
      }}
    >
      <GoogleIcon />
    </Button>
  );
};

export default CustomGoogleButton;
