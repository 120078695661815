import React from 'react';

interface PasswordRequirementsProps {
  password: string;
  colorConfig?: {
    textColor: string;
    activeColor: string;
    inactiveColor: string;
  };
}

const PasswordRequirements: React.FC<PasswordRequirementsProps> = ({
  password,
  colorConfig = {
    textColor: '#6B7280',
    activeColor: '#10B981',
    inactiveColor: '#6B7280',
  },
}) => {
  const isMinLength = password.length >= 8;
  const hasNumber = /[0-9]/.test(password);
  const hasUppercase = /[A-Z]/.test(password);

  return (
    <div style={{ color: `${colorConfig.textColor}` }} className="mt-2">
      <ul>
        <li>
          <span
            className="inline-block w-2 h-2 rounded-full mr-2"
            style={{
              backgroundColor: isMinLength ? colorConfig.activeColor : colorConfig.inactiveColor,
            }}
          />
          Must be at least 8 characters
        </li>
        <li>
          <span
            className="inline-block w-2 h-2 rounded-full mr-2"
            style={{
              backgroundColor: hasNumber ? colorConfig.activeColor : colorConfig.inactiveColor,
            }}
          />
          Include at least one number (0-9)
        </li>
        <li>
          <span
            className="inline-block w-2 h-2 rounded-full mr-2"
            style={{
              backgroundColor: hasUppercase ? colorConfig.activeColor : colorConfig.inactiveColor,
            }}
          />
          Include at least one uppercase letter (A-Z)
        </li>
      </ul>
    </div>
  );
};

export default PasswordRequirements;
