import React from 'react';

import Logo from '../../components/ui-kit/Logo';
import Typography from '../../components/ui-kit/Typography';

import CreateAccountForm from '../../components/CreateAccountForm';

const CreateAccount = () => {
  return (
    <div className="relative min-h-[100vh] flex flex-col justify-center items-center">
      <div className="container relative z-10">
        <div className="min-h-[100vh] flex flex-col gap-10">
          <div className="pt-10 px-5 text-center">
            <Logo color="primary" />
          </div>
          <Typography className="text-center" variant="h2">
            Create a new account
          </Typography>

          <div className="py-10 flex flex-col items-center justify-center">
            <CreateAccountForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAccount;
