import { API_BASE_URL } from '../../config/api';
import fetch, { fetchWithAuth, Auth } from '../../utils/fetch';
import Profile from '../../interfaces/Profile';

export interface LoginPayload {
  email: string;
  password: string;
}

export interface ResetPasswordPayload {
  token: string;
  password: string;
}

interface OccasionType {
  name: string;
  date: string;
}

export interface OccasionPayload {
  occasions: OccasionType[];
}

export const signIn = (data: LoginPayload) =>
  fetch<{ accessToken: string }>(`${API_BASE_URL}/auth/signin`, { method: 'POST', data });

export const signUp = (data: LoginPayload) =>
  fetch<{ accessToken: string }>(`${API_BASE_URL}/auth/signup`, { method: 'POST', data });

export const resetPassword = (data: ResetPasswordPayload) =>
  fetch<{ accessToken: string }>(`${API_BASE_URL}/auth/reset-password`, { method: 'POST', data });

export const googleSignIn = (code: string) =>
  fetch<{ accessToken: string }>(`${API_BASE_URL}/auth/google/signin`, {
    method: 'POST',
    data: { authCode: code },
  });

export const facebookSignIn = (accessToken: string) =>
  fetch<{ accessToken: string }>(`${API_BASE_URL}/auth/facebook/signin`, {
    method: 'POST',
    data: { accessToken },
  });

export const forgotPasswordEmail = (email: string) =>
  fetch<void>(`${API_BASE_URL}/auth/forgot-password?email=${encodeURIComponent(email)}`, {
    method: 'POST',
  });

export const loginGuest = () => fetch<{ accessToken: string }>(`${API_BASE_URL}/auth/guest`, { method: 'POST' });

export const getProfile = (auth: Auth) =>
  fetchWithAuth<Profile>(auth, `${API_BASE_URL}/auth/profile`, { method: 'GET' });

export const updateProfile = (auth: Auth, data: Profile) =>
  fetchWithAuth<Profile>(auth, `${API_BASE_URL}/users`, { method: 'PUT', data });

export const deleteAccount = (auth: Auth) =>
  fetchWithAuth<undefined>(auth, `${API_BASE_URL}/users`, { method: 'DELETE' });

export const updateUsersOccasions = (auth: Auth, data: OccasionPayload) =>
  fetchWithAuth<Profile>(auth, `${API_BASE_URL}/users/occasions`, {
    method: 'PUT',
    data,
  });
