import * as Yup from 'yup';

const createAccountSchema = Yup.object({
  email: Yup.string().email('Invalid email address').required('Required'),
  password: Yup.string()
    .min(8, 'Must be at least 8 characters')
    .matches(/[0-9]/, 'Must include at least one number (0-9)')
    .matches(/[A-Z]/, 'Must include at least one uppercase letter (A-Z)')
    .required('Required'),
  repeatPassword: Yup.string()
    .oneOf([Yup.ref('password'), undefined], 'Passwords must match')
    .required('Required'),
  terms: Yup.boolean().oneOf([true], 'You must accept the terms and conditions'),
});

export default createAccountSchema;
