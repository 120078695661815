import React from 'react';
import { createBrowserRouter, Navigate, Outlet } from 'react-router-dom';

import PrivateRoute from '../components/PrivateRoute';
import EnsureProfile from '../components/EnsureProfile';
import Login from '../pages/Login';
import Profile from '../pages/Profile';
import Connections from '../pages/Connections';
import Recommendations from '../pages/Recommendations';
import NewConnection from '../pages/NewConnection';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import CreateAccount from '../pages/CreateAccount';
import UserAccount from '../pages/UserAccount';
import InvitationPage from '../pages/InvitationPage';
import Occasions from '../pages/Occasions';
import UserOccasions from '../pages/UserOccasions';
import Events from '../pages/Events';
import Policy from '../pages/Policy';

const routes = createBrowserRouter([
  {
    path: '',
    element: (
      <PrivateRoute>
        <Outlet />
      </PrivateRoute>
    ),
    children: [
      {
        path: '',
        element: (
          <EnsureProfile>
            <Outlet />
          </EnsureProfile>
        ),
        children: [
          {
            index: true,
            element: <Connections />,
          },
          {
            path: 'connections/new',
            element: <NewConnection />,
          },
          {
            path: 'connections/:id/edit',
            element: <NewConnection />,
          },
          {
            path: 'connections/:id/occasion/add',
            element: <Occasions />,
          },
          {
            path: 'connections/:id',
            element: <Recommendations />,
          },
          {
            path: 'user-account',
            element: <UserAccount />,
          },
          {
            path: 'events',
            element: <Events />,
          },
        ],
      },
      {
        path: 'profile/:id',
        element: <Profile />,
      },
      {
        path: 'profile',
        element: <Profile />,
      },
      {
        path: 'profile/:id/occasion/add',
        element: <UserOccasions />,
      },
    ],
  },
  {
    path: 'login',
    element: <Login />,
  },
  {
    path: 'policy',
    element: <Policy />,
  },
  {
    path: 'forgot-password',
    element: <ForgotPassword />,
  },
  {
    path: 'reset-password',
    element: <ResetPassword />,
  },
  {
    path: 'create-account',
    element: <CreateAccount />,
  },
  {
    path: 'share-profile/:inviterId',
    element: <InvitationPage />,
  },
  {
    path: '*',
    element: <Navigate replace to="/" />,
  },
]);

export default routes;
