import React from 'react';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

interface GoBackButtonProps {
  color?: string;
  label?: string;
}

const GoBackButton: React.FC<GoBackButtonProps> = ({ label = 'Back', color = '#8ac7bb' }) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Button
      variant="text"
      color="primary"
      onClick={handleBack}
      startIcon={<ArrowBackIcon />}
      sx={{
        color,
        textTransform: 'initial',
      }}
    >
      {label}
    </Button>
  );
};

export default GoBackButton;
