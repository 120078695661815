import { API_BASE_URL } from '../../config/api';
import fetch, { Auth, fetchWithAuth } from '../../utils/fetch';

export interface DropdownsData {
  gender: string[];
  countries: string[];
  hobbiesAndInterests: string[];
  occasions: string[];
}

export interface CustomHobbiesDropdown {
  hobbiesAndInterests: string[];
}

export const getDropdowns = () => fetch<DropdownsData>(`${API_BASE_URL}/dropdowns`, { method: 'GET' });

export const getCustomDropdowns = (auth: Auth) =>
  fetchWithAuth<CustomHobbiesDropdown>(auth, `${API_BASE_URL}/users/custom-hobbies-and-interests`, { method: 'GET' });
