import React, { useState } from 'react';
import { useFormik } from 'formik';
import { Alert, CircularProgress } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';

import { useAuth } from '../../providers/auth';
import Input from '../ui-kit/Input';
import PasswordRequirements from '../PasswordRequirements';
import Button from '../ui-kit/Button';
import createAccountSchema from '../../schemas/createAccountShema';
import TermsCheckbox from './TermsCheckbox';
import SocialLogin from './SocialLogin';

interface CreateAccountFormValues {
  email: string;
  password: string;
  repeatPassword: string;
  terms: boolean;
}

const initialValues: CreateAccountFormValues = {
  email: '',
  password: '',
  repeatPassword: '',
  terms: false,
};

const handleError = (error: unknown): string => {
  if (error instanceof AxiosError && error.response?.data?.message) {
    return 'This email is already registered. Please log in or use a different email.';
  }

  return 'Something went wrong. Try again later, please.';
};

const CreateAccountForm: React.FC = () => {
  const { loading, handleSignUp } = useAuth();
  const [generalError, setGeneralError] = useState<string | string[]>('');
  const navigate = useNavigate();

  const formik = useFormik<CreateAccountFormValues>({
    initialValues,
    validationSchema: createAccountSchema,
    onSubmit: async values => {
      try {
        const { email, password } = values;
        await handleSignUp({
          password,
          email: email.toLowerCase(),
        });
        return navigate('/profile');
      } catch (e) {
        const errorMessage = handleError(e);
        return setGeneralError(errorMessage);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} className="flex flex-col gap-6 w-full">
      {!!generalError && (
        <div className="pb-5">
          <Alert severity="error">{Array.isArray(generalError) ? generalError.at(-1) : generalError}</Alert>
        </div>
      )}
      <Input
        placeholder="Email Address"
        name="email"
        autoComplete="off"
        value={formik.values.email}
        error={!!(formik.touched.email && formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email ? formik.errors.email : undefined}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
      <Input
        placeholder="Password"
        type="password"
        name="password"
        value={formik.values.password}
        error={!!(formik.touched.password && formik.errors.password)}
        helperText={formik.touched.password && formik.errors.password ? formik.errors.password : undefined}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
      <Input
        placeholder="Repeat Password"
        type="password"
        name="repeatPassword"
        value={formik.values.repeatPassword}
        error={!!(formik.touched.repeatPassword && formik.errors.repeatPassword)}
        helperText={
          formik.touched.repeatPassword && formik.errors.repeatPassword ? formik.errors.repeatPassword : undefined
        }
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />

      <div className="px-5 flex flex-col gap-10">
        <PasswordRequirements password={formik.values.password} />
        <TermsCheckbox formik={formik} />
      </div>

      <Button
        type="submit"
        color="secondary"
        sx={{ textTransform: 'initial', width: '100%' }}
        disabled={
          !!(formik.touched.email && formik.errors.email) ||
          !!(formik.touched.password && formik.errors.password) ||
          loading
        }
        startIcon={loading ? <CircularProgress size={18} /> : null}
      >
        Submit
      </Button>

      <div className="flex flex-col items-center">
        <SocialLogin setGeneralError={setGeneralError} />
      </div>
    </form>
  );
};

export default CreateAccountForm;
