import { useState, useEffect, useCallback } from 'react';

import Contact, { ContactCreateDTO } from '../../interfaces/Contact';
import { useAuth } from '../../providers/auth';
import {
  getContacts,
  getContactById,
  createContact,
  updateContact,
  deleteContacts,
  updateProfileViaInvite,
  updateContactsOcassions,
} from './api';
import { OccasionPayload } from '../auth/api';

export interface ContactsHook {
  loading: boolean;
  data: Contact[];
  selected: Contact | null;
  handleFetch(throwError?: boolean): Promise<void>;
  handleFetchById(id: string, throwError?: boolean): Promise<void>;
  handleCreate(data: ContactCreateDTO, throwError?: boolean): Promise<void>;
  handleUpdate(id: string, data: ContactCreateDTO, throwError?: boolean): Promise<void>;
  handleUpdateContactsOccasion(id: string, data: OccasionPayload, throwError?: boolean): Promise<void>;
  handleDelete(id: string, throwError?: boolean): Promise<void>;
  handleUpdateProfileViaInvite(inviterId: string): Promise<unknown>;
}

export interface UseContactsOpts {
  fetchAll?: boolean;
  fetchById?: string;
}

const useContacts = ({ fetchAll, fetchById }: UseContactsOpts = {}): ContactsHook => {
  const auth = useAuth();

  const [loadingList, setLoadingList] = useState<ContactsHook['loading']>(fetchAll || false);
  const [loadingById, setLoadingById] = useState<ContactsHook['loading']>(!!fetchById || false);
  const [loading, setLoading] = useState<ContactsHook['loading']>(false);
  const [data, setData] = useState<ContactsHook['data']>([]);
  const [selected, setSelected] = useState<ContactsHook['selected'] | null>(null);

  const handleFetch = async (throwError = false) => {
    setLoadingList(true);
    try {
      const { data: contacts } = await getContacts(auth);
      setData(contacts);
    } catch (err) {
      if (throwError) {
        throw err;
      }
    } finally {
      setLoadingList(false);
    }
  };

  const handleFetchById = async (id: string, throwError = false) => {
    setLoadingById(true);
    try {
      const { data: contact } = await getContactById(auth, id);
      setSelected(contact);
    } catch (err) {
      if (throwError) {
        throw err;
      }
    } finally {
      setLoadingById(false);
    }
  };

  const handleCreate = async (payload: ContactCreateDTO, throwError = false) => {
    setLoading(true);
    try {
      await createContact(auth, payload);
    } catch (err) {
      if (throwError) {
        throw err;
      }
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async (id: string, payload: ContactCreateDTO, throwError = false) => {
    setLoading(true);
    try {
      await updateContact(auth, id, payload);
    } catch (err) {
      if (throwError) {
        throw err;
      }
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateContactsOccasion = async (id: string, payload: OccasionPayload, throwError = false) => {
    setLoading(true);
    try {
      await updateContactsOcassions(auth, id, payload);
    } catch (err) {
      if (throwError) {
        throw err;
      }
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateProfileViaInvite = useCallback(
    async (inviterId: string) => {
      setLoading(true);
      try {
        await updateProfileViaInvite(auth, inviterId);
        return localStorage.removeItem('inviterId');
      } catch (err) {
        return err;
      } finally {
        setLoading(false);
      }
    },
    [auth],
  );

  const handleDelete = async (id: string, throwError = false) => {
    setLoading(true);
    try {
      await deleteContacts(auth, id);
    } catch (err) {
      if (throwError) {
        throw err;
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (fetchAll) {
      handleFetch();
    }
  }, [fetchAll]);

  useEffect(() => {
    if (fetchById) {
      handleFetchById(fetchById);
    }
  }, [fetchById]);

  return {
    data,
    selected,
    loading: loading || loadingById || loadingList,
    handleFetch,
    handleFetchById,
    handleCreate,
    handleUpdate,
    handleDelete,
    handleUpdateProfileViaInvite,
    handleUpdateContactsOccasion,
  };
};

export default useContacts;
