import { useState, useEffect } from 'react';
import { getDropdowns, DropdownsData, getCustomDropdowns, CustomHobbiesDropdown } from './api';
import { useAuth } from '../../providers/auth';

const initialValue: DropdownsData = {
  gender: [],
  countries: [],
  hobbiesAndInterests: [],
  occasions: [],
};

const initlaCustomHobbiesValues: CustomHobbiesDropdown = {
  hobbiesAndInterests: [],
};

const useDropdowns = (autoFetch = true) => {
  const auth = useAuth();
  const [dropdowns, setDropdowns] = useState(initialValue);
  const [customHobbies, setCustomHobbies] = useState(initlaCustomHobbiesValues);
  const [loading, setLoading] = useState(false);

  const fetch = async () => {
    setLoading(true);
    try {
      const { data } = await getDropdowns();
      const { data: customInterests } = await getCustomDropdowns(auth);

      const combinedHobbies = [...data.hobbiesAndInterests, ...customInterests.hobbiesAndInterests];

      setDropdowns({
        ...data,
        hobbiesAndInterests: combinedHobbies,
      });

      setCustomHobbies(customInterests);
    } catch (err) {
      /* eslint-disable-next-line no-console */
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (autoFetch) {
      fetch();
    }
  }, [autoFetch]);

  return {
    dropdowns,
    setDropdowns,
    loading,
    fetch,
    customHobbies,
  };
};

export default useDropdowns;
