import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Alert, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import GoBackButton from '../../components/GoBackButton';
import Input from '../../components/ui-kit/Input';
import Button from '../../components/ui-kit/Button';
import { ReactComponent as LogoIcon } from '../../assets/present.svg';
import PasswordRequirements from '../../components/PasswordRequirements';
import Modal from '../../components/Modal';
import resetPasswordSchema from '../../schemas/resetPasswordSchema';
import { useAuth } from '../../providers/auth';

interface ResetPasswordFormValues {
  password: string;
  confirmPassword: string;
}

const ResetPasswordForm: ResetPasswordFormValues = {
  password: '',
  confirmPassword: '',
};

const ResetPassword = () => {
  const [token, setToken] = useState<string | null>(null);
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const [generalError, setGeneralError] = useState<string | string[]>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(prevShowPassword => !prevShowPassword);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(prevShowPassword => !prevShowPassword);
  };

  const navigate = useNavigate();
  const { handleResetPassword } = useAuth();

  const formik = useFormik({
    initialValues: ResetPasswordForm,
    validationSchema: resetPasswordSchema,
    onSubmit: async values => {
      try {
        if (token) {
          await handleResetPassword({ token, password: values.password });
          setIsModalOpened(true);
        }
      } catch (error) {
        setGeneralError('Could not reset password');
      }
    },
  });

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const tokenFromUrl = params.get('token');
    setToken(tokenFromUrl);
  }, []);

  return (
    <div className="relative min-h-[100vh] bg-grey-main">
      <div className="container relative z-10 py-10">
        <GoBackButton />

        <div className="relative z-10 min-h-[100vh] flex flex-col">
          <div className="flex justify-center items-center">
            <LogoIcon />
          </div>

          <div className="flex flex-col items-center px-5 mt-5">
            <p className="text-yellow-main text-2xl font-semibold mb-10">Change password</p>

            <form onSubmit={formik.handleSubmit} className="flex flex-col w-full gap-6">
              {!!generalError && (
                <div className="pb-5">
                  <Alert severity="error">{Array.isArray(generalError) ? generalError.at(-1) : generalError}</Alert>
                </div>
              )}

              <Input
                fullWidth
                name="password"
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                value={formik.values.password}
                error={formik.touched.password && !!formik.errors.password}
                helperText={formik.touched.password && formik.errors.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" sx={{ mr: 1 }}>
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleTogglePasswordVisibility}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Input
                fullWidth
                name="confirmPassword"
                type={showConfirmPassword ? 'text' : 'password'}
                placeholder="Repeat password"
                value={formik.values.confirmPassword}
                error={formik.touched.confirmPassword && !!formik.errors.confirmPassword}
                helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" sx={{ mr: 1 }}>
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleToggleConfirmPasswordVisibility}
                        edge="end"
                      >
                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <div className="px-5">
                <PasswordRequirements
                  password={formik.values.password}
                  colorConfig={{
                    textColor: '#E3F4F3',
                    activeColor: '#FFDC73',
                    inactiveColor: '#002638',
                  }}
                />
              </div>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!formik.isValid || !formik.dirty}
                fullWidth
              >
                Submit
              </Button>
            </form>
          </div>
        </div>

        <Modal
          open={isModalOpened}
          onClose={() => setIsModalOpened(false)}
          titleColor="#2FAE94"
          footer={
            <Button
              type="button"
              variant="text"
              onClick={() => {
                navigate('/login');
              }}
              sx={{ color: '#2FAE94', textTransform: 'initial' }}
            >
              Ok
            </Button>
          }
        >
          <p className="text-green-main font-semibold">Your password was successfully changed</p>
        </Modal>
      </div>
    </div>
  );
};

export default ResetPassword;
