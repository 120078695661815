/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography, Box } from '@mui/material';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import IconButton from '../../../components/ui-kit/Button/IconButton';

interface FAQDialogProps {
  open: boolean;
  onClose: () => void;
}

const FAQDialog: React.FC<FAQDialogProps> = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle className="flex items-center justify-between">
        <Typography variant="h5" fontWeight="bold">
          About / FAQ
        </Typography>
        <IconButton onClick={onClose}>
          <ClearRoundedIcon fontSize="large" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box mb={3}>
          <Typography variant="body1">
            At Offragift, we're passionate about transforming the gift-giving experience into something truly special
            and memorable. We understand that finding the perfect gift can be a daunting task, often filled with
            uncertainty and guesswork.
          </Typography>
        </Box>
        <Box mb={3}>
          <Typography variant="body1">
            That's why we've created a platform that takes the stress out of gift-giving and replaces it with excitement
            and anticipation. Our AI-powered machine curates a diverse selection of gift suggestions, ranging from
            unique and trendy items to timeless classics.
          </Typography>
        </Box>
        <Box mb={3}>
          <Typography variant="h6" fontWeight="bold">
            Frequently Asked Questions
          </Typography>
        </Box>
        <Box mb={3}>
          <Typography variant="body1" fontWeight="bold">
            What is Offra?
          </Typography>
          <Typography variant="body1">
            Offra is a unique gift-giving app designed to make the process of selecting and giving gifts easier, more
            personal, and more enjoyable.
          </Typography>
        </Box>
        <Box mb={3}>
          <Typography variant="body1" fontWeight="bold">
            How does Offra work?
          </Typography>
          <Typography variant="body1">
            Offra is a platform where clients can receive personalized gift suggestions for their friends and family.
            Simply create your profile and share it with loved ones or customize a recipient's profile to receive
            tailored gift ideas.
          </Typography>
        </Box>
        <Box mb={3}>
          <Typography variant="body1" fontWeight="bold">
            How can I have the best experience in Offra?
          </Typography>
          <Typography variant="body1" component="div">
            <ul style={{ marginTop: '8px', marginLeft: '20px', lineHeight: '1.8' }}>
              <li>Sign in or log in to Offragift.</li>
              <li>Create your profile and add your interests, hobbies, and preferences.</li>
              <li>Invite family and friends to join your Offra connections.</li>
              <li>Set important dates to receive reminders and recommendations.</li>
            </ul>
          </Typography>
        </Box>
        <Box mb={3}>
          <Typography variant="body1" fontWeight="bold" mb={3}>
            How can I delete my OFFRA account?
          </Typography>
          <Typography variant="body1" fontWeight="medium">
            To delete your OFFRA account, follow these steps:
          </Typography>
          <Typography variant="body1" component="div" mb={3}>
            <ul style={{ marginTop: '8px', marginLeft: '20px', lineHeight: '1.8' }}>
              <li>Sign in or log in to Offragift.</li>
              <li>Create your profile and add your interests, hobbies, and preferences.</li>
              <li>Invite family and friends to join your Offra connections.</li>
              <li>Set important dates to receive reminders and recommendations.</li>
            </ul>
          </Typography>
          <Typography variant="body1">
            Once completed, your account and all associated data will be permanently deleted from our database. Please
            note that this action is irreversible.
          </Typography>
        </Box>
        <Box mb={3}>
          <Typography variant="body1" fontWeight="bold">
            Is Offra suitable for all occasions?
          </Typography>
          <Typography variant="body1">
            Yes! Offra helps you find a gift for any occasion, whether it's a birthday, anniversary, or holiday.
          </Typography>
        </Box>
        <Box mb={3}>
          <Typography variant="body1" fontWeight="bold">
            How secure is my personal information?
          </Typography>
          <Typography variant="body1">
            We take your privacy seriously and use industry-standard encryption protocols to ensure data security.
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FAQDialog;
