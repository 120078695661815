import Budget from '../config/budget';

const isBudgetValid = (budgetValue: string): boolean => {
  if (!budgetValue || Number.isNaN(Number(budgetValue)) || /^0[0-9]/.test(budgetValue)) return false;

  const budget = Number.parseFloat(budgetValue);
  return budget >= Budget.MIN && budget <= Budget.MAX;
};

export default isBudgetValid;
