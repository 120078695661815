import { Checkbox } from '@mui/material';
import React from 'react';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Button from '../../ui-kit/Button';

const TermsCheckbox: React.FC<{ formik: any }> = ({ formik }) => {
  const handlePolicyClick = () => {
    window.open('/policy', '_blank');
  };

  return (
    <div className="flex items-center flex-col">
      <div className="self-start flex items-center text-gray-500 -ml-5">
        <Checkbox
          name="terms"
          checked={formik.values.terms}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          sx={{
            '& .MuiSvgIcon-root': {
              fontSize: 24,
              backgroundColor: 'white',
              borderRadius: '3px',
            },
            '&.Mui-checked .MuiSvgIcon-root': {
              backgroundColor: 'transparent',
              color: 'green',
            },
          }}
          icon={<CheckBoxOutlineBlankIcon sx={{ color: 'transparent' }} />}
          checkedIcon={<CheckBoxIcon sx={{ color: 'green' }} />}
        />
        <p>I have read the</p>
        <Button
          variant="text"
          color="secondary"
          className="!text-blue-light !font-normal !normal-case !text-[16px] [text-shadow:_0_0px_10px_rgb(255_255_255_/_70%)]"
          onClick={handlePolicyClick}
        >
          Terms and conditions
        </Button>
      </div>
      {formik.touched.terms && formik.errors.terms && (
        <div className="text-red-500 text-sm self-start -ml-3">{formik.errors.terms}</div>
      )}
    </div>
  );
};

export default TermsCheckbox;
