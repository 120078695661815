import React, { useState } from 'react';
import { useNavigate, useParams, useSearchParams, Navigate } from 'react-router-dom';
import { Alert, CircularProgress, Typography } from '@mui/material';

import Budget from '../../config/budget';
import useContacts from '../../data-hooks/contacts/useContacts';
import useRecommendations from '../../data-hooks/recommendations/useRecommendations';
import { ReactComponent as LogoIcon } from '../../assets/present.svg';
import FindUsSection from '../UserAccount/FindUsSection';
import BottomNavBar from '../../components/NavigationBar';
import RecommendationCard from '../../components/RecommendationCard';
import RefreshSuggestions from './RefreshSuggestions';

const Recommendations: React.FC = () => {
  const navigate = useNavigate();
  const { id: connectionId } = useParams();
  const [queryParams] = useSearchParams();
  const [refreshing, setRefreshing] = useState(false);

  const queryOccasionId = queryParams.get('occasionId') || '';
  let budget = Budget.MAX;
  if (queryParams.get('budget')) {
    budget = Number.parseFloat(queryParams.get('budget') || '');
    if (budget < Budget.MIN) {
      budget = Budget.MIN;
    }
    if (budget > Budget.MAX) {
      budget = Budget.MAX;
    }
  }

  const { selected: connection, loading: connectionLoading } = useContacts({ fetchById: connectionId });
  const {
    data: recommends,
    reasons,
    loading: recommendsLoading,
    error,
    fetch,
  } = useRecommendations({ occasionId: queryOccasionId, budget });

  const occasion = connection?.occasions?.find(({ id: occasionId }) => occasionId === queryOccasionId);

  const formatPrice = (price: string): string => {
    let priceSymbol: string;
    if (price.includes('€')) {
      priceSymbol = '€';
    } else {
      priceSymbol = '$';
    }

    return price.split(priceSymbol).reduce((res, item, i) => {
      return item?.length ? `${res} ${i > 0 && res.length ? '/' : ''} ${item.trim()}${priceSymbol}` : res;
    }, '');
  };

  const handleMoreSuggestions = async () => {
    try {
      setRefreshing(true);
      await fetch({ occasionId: queryOccasionId, budget }, true);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Failed to fetch more suggestions:', err);
    } finally {
      setRefreshing(false);
    }
  };

  if (!queryOccasionId || !budget || (!connectionLoading && !occasion)) {
    return <Navigate replace to="/" />;
  }

  return (
    <div className="flex flex-col min-h-screen relative bg-green-main">
      <div className="p-5 text-center self-center">
        <LogoIcon />
      </div>
      <div
        className="!h-[50px] text-white !bg-[length:100%_100%]"
        style={{ background: 'url("/section-curve.png") no-repeat' }}
      />
      <div className="grow flex bg-white pb-20">
        <div className="container min-h-full flex flex-col">
          <div className="min-h-[48px] mb-3">
            <Typography variant="h5" className="text-center text-blue-light">
              Recommendations for
              <br />
              {!!connection && <span className="!break-words">{connection?.name}</span>}
            </Typography>
          </div>
          {connectionLoading && !connection && (
            <div className="flex justify-center items-center h-[40vh]">
              <CircularProgress size={40} />
            </div>
          )}
          {!!connection && (
            <div className="pb-3">
              <div className="pt-5">
                {!!error && (
                  <div>
                    <Alert severity="error">Something went wrong. Try again later, please.</Alert>
                  </div>
                )}
                {!recommends?.length && !recommendsLoading && !error && (
                  <div>
                    <Alert severity="info">No recommendations. Try again later, please.</Alert>
                  </div>
                )}
                {!refreshing && recommends.length > 0 && (
                  <div className="mb-3">
                    <RefreshSuggestions onClick={handleMoreSuggestions} />
                  </div>
                )}

                {!!recommends?.length && !refreshing ? (
                  recommends.map(({ id, title, originalTitle, image, link, price }) => (
                    <RecommendationCard
                      key={id}
                      id={id}
                      title={title}
                      originalTitle={originalTitle}
                      image={image}
                      link={link}
                      price={price}
                      reason={reasons?.[id]}
                      loading={recommendsLoading}
                      formatPrice={formatPrice}
                    />
                  ))
                ) : (
                  <div className="flex justify-center items-center py-10">
                    <CircularProgress size={40} />
                  </div>
                )}
              </div>
            </div>
          )}
          <FindUsSection color="#009F93" />
          <BottomNavBar
            onMenuClick={() => navigate('/user-account')}
            onConnectionsClick={() => navigate('/')}
            onEventsClick={() => navigate('/events')}
          />
        </div>
      </div>
    </div>
  );
};

export default Recommendations;
