import React, { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { AxiosError } from 'axios';
import { CodeResponse, GoogleOAuthProvider } from '@react-oauth/google';
import { Button } from '@mui/material';

import { AuthError } from '../../utils/fetch';
import { useAuth } from '../../providers/auth';
import Logo from '../../components/ui-kit/Logo';
import LoginForm, { FormValues } from '../../components/LoginForm';
import Footer from '../../components/Footer';
import CustomGoogleButton from '../../components/CustomGoogleButton';
import FacebookLoginButton from '../../components/FacebookAuthButton';
import { GOOGLE_API_KEY } from '../../config/api';

const initialValues: FormValues = {
  email: '',
  password: '',
};

const validationSchema = Yup.object<FormValues>().shape({
  email: Yup.string().email('Please enter a valid email address').required('Email is a required field'),
  password: Yup.string()
    .required('Password is a required field')
    .min(8, ({ min }) => `Please enter at least ${min} characters`)
    .max(20, ({ max }) => `Password must be shorter than or equal to ${max} characters`)
    .test('Spaces', 'The password can’t contain spaces', v => !v?.includes(' ')),
});

const Login: React.FC = () => {
  const { accessToken, loading, handleLogin, handleGoogleLogin, handleFacebookLogin } = useAuth();
  const [generalError, setGeneralError] = useState<string | string[]>('');
  const navigate = useNavigate();

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } = useFormik<FormValues>({
    initialValues,
    validationSchema,
    onSubmit: async (v: FormValues) => {
      try {
        const loginPayload = {
          ...v,
          email: v.email.toLowerCase(),
        };

        return await handleLogin(loginPayload);
      } catch (e) {
        if (e instanceof AuthError) {
          return setGeneralError('Wrong email or password');
        }
        if (e instanceof AxiosError && e.response?.data?.message) {
          return setGeneralError('Invalid email or password. Please try again.');
        }

        return setGeneralError('Something went wrong. Try again later, please.');
      }
    },
  });

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    setGeneralError('');
    handleSubmit(e);
  };

  const googleLoginCallback = async (response: CodeResponse) => {
    try {
      await handleGoogleLogin(response.code);
    } catch (error) {
      if (error instanceof AuthError) {
        setGeneralError('Google authentication failed.');
      } else {
        setGeneralError('Something went wrong during Google login.');
      }
    }
  };

  const handleFacebookCallback = async (codeToken: string) => {
    try {
      await handleFacebookLogin(codeToken);
    } catch (error) {
      if (error instanceof AuthError) {
        setGeneralError('Google authentication failed.');
      } else {
        setGeneralError('Something went wrong during Facebook login.');
      }
    }
  };

  const handleCreateAccountClick = () => {
    navigate('/create-account');
  };

  if (accessToken) {
    return <Navigate replace to="/" />;
  }

  return (
    <div className="relative min-h-[100vh]">
      <div className="container relative z-10">
        <div className="relative z-10 min-h-[100vh] flex flex-col justify-between">
          <div className="pt-10 px-5">
            <Logo color="primary" />
          </div>
          <div className="py-10 flex flex-col items-center justify-center">
            <LoginForm
              values={values}
              errors={errors}
              touched={touched}
              loading={loading}
              generalError={generalError}
              handleChange={handleChange}
              handleBlur={handleBlur}
              handleSubmit={submit}
            />
            <div className="relative py-4 text-center text-blue-main font-semibold before:absolute before:content-[''] before:w-5 before:h-[1px] before:bg-blue-main before:-left-6 after:absolute after:content-[''] after:w-5 after:h-[1px] after:bg-blue-main after:-right-6 before:top-1/2 after:top-1/2">
              Or Sign in with
            </div>
            <div className="mb-12">
              <div className="flex gap-4">
                <GoogleOAuthProvider clientId={GOOGLE_API_KEY!}>
                  <CustomGoogleButton loginCallback={googleLoginCallback} setGeneralError={setGeneralError} />
                </GoogleOAuthProvider>
                <FacebookLoginButton loginCallback={handleFacebookCallback} setGeneralError={setGeneralError} />
              </div>
            </div>
            <Button
              onClick={handleCreateAccountClick}
              color="secondary"
              sx={{
                width: '75%',
                textTransform: 'initial',
              }}
            >
              Create a new account
            </Button>
          </div>
          <div className="relative z-10 pb-5">
            <Footer />
          </div>
        </div>
        <div
          className="w-[5%] max-w-[12px] aspect-[0.309] absolute top-[25%] right-[40px] !bg-center !bg-contain"
          style={{ background: 'url("tape_1.png") no-repeat' }}
        />
        <div
          className="w-[10%] max-w-[30px] aspect-[1.227] absolute top-[40%] left-[-10px] !bg-center !bg-contain"
          style={{ background: 'url("tape_2.png") no-repeat' }}
        />
        <div
          className="w-[10%] max-w-[30px] aspect-[1.08] absolute bottom-[15%] right-[10px] !bg-center !bg-contain"
          style={{ background: 'url("tape_3.png") no-repeat' }}
        />
        <div
          className="w-[10%] max-w-[15px] aspect-[1.187] absolute bottom-[12%] left-[5%] !bg-center !bg-contain"
          style={{ background: 'url("tape_4.png") no-repeat' }}
        />
        <div
          className="w-[10%] max-w-[16px] aspect-[1.225] absolute bottom-[0%] left-[9%] !bg-center !bg-contain"
          style={{ background: 'url("tape_5.png") no-repeat' }}
        />
        <div
          className="w-[10%] max-w-[30px] aspect-[0.654] absolute bottom-[0%] left-[45%] !bg-center !bg-contain"
          style={{ background: 'url("tape_6.png") no-repeat' }}
        />
      </div>
      <div
        className="w-[40%] max-w-[200px] aspect-[0.814] absolute -bottom-[0px] -right-[0px] !bg-center !bg-contain"
        style={{ background: 'url("present_bg_1.png") no-repeat' }}
      />
      <div
        className="w-[20%] max-w-[100px] aspect-[0.605] absolute bottom-[25%] left-[0px] !bg-center !bg-contain"
        style={{ background: 'url("present_bg_2.png") no-repeat' }}
      />
    </div>
  );
};

export default Login;
