import { useCallback } from 'react';

const useProfileSharing = (profileId?: string) => {
  const text = `Join me on this platform! 🎉`;
  const url = `${window.location.origin}/share-profile/${profileId}`;

  const getShareData = useCallback((): { text?: string; url: string } | undefined => {
    if (typeof navigator.canShare !== 'function') return undefined;

    if (navigator.canShare({ text, url })) {
      return { text, url };
    }

    if (navigator.canShare({ url })) {
      return { url };
    }

    return undefined;
  }, [text, url]);

  const canShare = useCallback(() => {
    return typeof navigator.canShare === 'function' && !!getShareData();
  }, [getShareData]);

  const copy = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(`${text} ${url}`);
    } catch (err) {
      /* eslint-disable-next-line no-console */
      console.error('Error copying to clipboard:', err);
    }
  }, [text, url]);

  const share = useCallback(async () => {
    try {
      if (canShare()) {
        await navigator.share(getShareData());
      } else {
        await copy();
      }
    } catch (err) {
      /* eslint-disable-next-line no-console */
      console.error('Error sharing profile:', err);
      await copy(); // Fallback to copying if sharing fails
    }
  }, [canShare, copy, getShareData]);

  return {
    share,
    canShare,
    copy,
  };
};

export default useProfileSharing;
