import { useState, useEffect } from 'react';

import Recommendation from '../../interfaces/Recommendation';
import RecommendationReasons from '../../interfaces/RecommendationReasons';
import { useAuth } from '../../providers/auth';
import { getRecommendations, GetRecommendsPayload, getRecommendationReasons } from './api';

export interface RecommendationsHook {
  data: Recommendation[];
  reasons: RecommendationReasons | null;
  loading: boolean;
  error: any;
  fetch(payload: GetRecommendsPayload, throwError?: boolean): Promise<void>;
}

const useRecommendations = ({ occasionId, budget }: Partial<GetRecommendsPayload> = {}): RecommendationsHook => {
  const auth = useAuth();

  const [data, setData] = useState<Recommendation[]>([]);
  const [reasons, setReasons] = useState<RecommendationReasons | null>(null);
  const [loading, setLoading] = useState(!!(occasionId && budget) || false);
  const [error, setError] = useState<any>(null);

  const fetchReasons = async (id: string, throwError?: boolean) => {
    setLoading(true);
    try {
      const { data: respData } = await getRecommendationReasons(auth, id);
      setReasons(respData);
      setError(null);
    } catch (err) {
      setError(err);

      if (throwError) {
        throw err;
      }
    } finally {
      setLoading(false);
    }
  };

  const fetch = async (payload: GetRecommendsPayload, append: boolean, throwError?: boolean) => {
    setLoading(true);
    try {
      const { data: recommends, headers } = await getRecommendations(auth, payload);
      setData(prev => {
        const combined = append ? [...recommends, ...prev] : recommends;
        const unique = Array.from(new Map(combined.map(item => [item.id, item])).values());
        return unique;
      });
      setError(null);

      const correlationId = headers?.['x-correlation-id'];
      if (correlationId) {
        await fetchReasons(correlationId, throwError);
      }
    } catch (err) {
      setError(err);

      if (throwError) {
        throw err;
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (occasionId && budget) {
      fetch({ occasionId, budget }, false);
    }
  }, [occasionId, budget]);

  return {
    data,
    reasons,
    loading,
    error,
    fetch,
  };
};

export default useRecommendations;
