import * as Yup from 'yup';

const resetPasswordSchema = Yup.object({
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Must be at least 8 characters')
    .matches(/[0-9]/, 'Must include at least one number (0-9)')
    .matches(/[A-Z]/, 'Must include at least one uppercase letter (A-Z)'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), undefined], 'Passwords must match')
    .required('Please confirm your password'),
});

export default resetPasswordSchema;
