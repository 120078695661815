/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, List, ListItem, ListItemText } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface AccordionItem {
  label: string;
  onClick: () => void;
}

interface CustomAccordionProps {
  title: string;
  icon: React.ElementType;
  items: AccordionItem[];
  isOpen: boolean;
  onToggle: () => void;
}

const CustomAccordion: React.FC<CustomAccordionProps> = ({ title, icon: Icon, items, isOpen, onToggle }) => {
  return (
    <Accordion
      disableGutters
      expanded={isOpen}
      sx={{ boxShadow: 'none', backgroundColor: 'transparent', border: 'none', '&::before': { display: 'none' } }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: 'inherit' }} />}
        onClick={onToggle}
        sx={{
          backgroundColor: 'transparent',
          boxShadow: 'none',
          padding: 0,
          display: 'inline-flex',
          alignItems: 'center',
          '&:hover': { backgroundColor: 'transparent' },
          '& .MuiAccordionSummary-content': { alignItems: 'center' },
        }}
      >
        <Icon sx={{ marginRight: 4, width: '20px', height: '20px' }} />
        <p className="font-semibold">{title}</p>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0, pl: 8 }}>
        <List component="div" disablePadding sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
          {items.map((item: any, index: number) => (
            <ListItem
              key={index}
              button
              sx={{ padding: 0, '&:hover': { backgroundColor: 'transparent' } }}
              onClick={item.onClick}
            >
              <ListItemText primary={item.label} sx={{ margin: 0 }} />
            </ListItem>
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

export default CustomAccordion;
