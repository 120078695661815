import React from 'react';
import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as LogoIcon } from '../../assets/present.svg';
import useNotification from '../../data-hooks/notifications/useNotification';
import EventCard from './EventCard';
import BottomNavBar from '../../components/NavigationBar';

const Events = () => {
  const { data: events, loading } = useNotification({ fetchAll: true });
  const navigate = useNavigate();

  return (
    <div className="flex flex-col min-h-screen bg-green-main relative gap-10 p-5">
      <div className="flex flex-col gap-10 p-5 items-center">
        <div className="text-center self-center">
          <LogoIcon />
        </div>
        <h2 className="text-center text-[#F9F8FA] text-xl">Upcoming events</h2>
        <div className="flex flex-col gap-5">
          {loading ? (
            <div className="flex justify-center py-[40vh]">
              <CircularProgress size={40} />
            </div>
          ) : (
            events?.map(event => (
              <EventCard key={event.isGlobal ? `${event.name}+${event.date}` : event.id} event={event} />
            ))
          )}
        </div>
      </div>

      <BottomNavBar
        onMenuClick={() => navigate('/user-account')}
        onConnectionsClick={() => navigate('/')}
        onEventsClick={() => navigate('/events')}
      />
    </div>
  );
};

export default Events;
