import React from 'react';

export interface Props {
  color?: 'primary' | 'gray';
  classnames?: string;
}

const Logo: React.FC<Props> = ({ color, classnames }) => (
  <div className="flex justify-center">
    <img
      src={color === 'primary' ? '/logo_b_1.svg' : '/logo_y_1.svg'}
      alt="OFFRA"
      className={`aspect-[300/69] ${classnames}`}
    />
  </div>
);

export default Logo;
