import { useState, useEffect, useCallback } from 'react';

import Profile, { ProfileUpdateDto } from '../../interfaces/Profile';
import {
  loginGuest,
  getProfile,
  updateProfile,
  LoginPayload,
  signIn,
  googleSignIn,
  signUp,
  forgotPasswordEmail,
  resetPassword,
  ResetPasswordPayload,
  deleteAccount,
  OccasionPayload,
  updateUsersOccasions,
  facebookSignIn,
} from './api';

export interface AuthHook {
  loading: boolean;
  initialized: boolean;
  accessToken: string | null;
  profile: Profile | null;
  handleLogin(data: LoginPayload, throwError?: boolean): Promise<Profile | null>;
  handleSignUp(data: LoginPayload, throwError?: boolean): Promise<Profile | null>;
  handleLoginGuest(throwError?: boolean): Promise<Profile | null>;
  handleGoogleLogin(code: string, throwError?: boolean): Promise<any>;
  handleFacebookLogin(code: string, throwError?: boolean): Promise<any>;
  handleResetPassword(data: ResetPasswordPayload, throwError?: boolean): Promise<any>;
  handleLogout(): Promise<void>;
  handleUpdateProfile(data: ProfileUpdateDto, throwError?: boolean): Promise<void>;
  handleUpdateUsersOccasions(data: OccasionPayload, throwError?: boolean): Promise<void>;
  sendResetPasswordEmail(email: string, throwError?: boolean): Promise<void>;
  handleDeleteAccount(token: string, throwError?: boolean): Promise<null>;
}

const useAuth = (): AuthHook => {
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState<Profile | null>(null);
  const [accessToken, setAccessToken] = useState<string | null>(localStorage.getItem('accessToken') || null);

  const handleSetAccessToken = useCallback(
    (token: string | null) => {
      setAccessToken(token);
      if (token) {
        localStorage.setItem('accessToken', token);
      } else {
        localStorage.removeItem('accessToken');
      }
    },
    [setAccessToken, localStorage.setItem, localStorage.removeItem],
  );

  const handleLogout = useCallback(async () => handleSetAccessToken(null), [handleSetAccessToken]);

  const loadProfile = useCallback(
    async (token: string, throwError = true): Promise<Profile | null> => {
      let result = null;
      setLoading(true);
      try {
        const { data } = await getProfile({ accessToken: token, handleLogout });
        setProfile(data);
        result = data;
      } catch (err) {
        if (throwError) {
          throw err;
        }
      } finally {
        setLoading(false);
      }

      return result;
    },
    [setLoading, setProfile, getProfile, handleLogout],
  );

  const handleUpdateProfile = useCallback(
    async (payload: Profile, throwError = true) => {
      setLoading(true);
      try {
        const { data } = await updateProfile({ accessToken, handleLogout }, payload);
        setProfile(data);
      } catch (err) {
        if (throwError) {
          throw err;
        }
      } finally {
        setLoading(false);
      }
    },
    [setLoading, setProfile, updateProfile, accessToken, handleLogout],
  );

  const handleUpdateUsersOccasions = useCallback(
    async (payload: OccasionPayload, throwError = true) => {
      setLoading(true);
      try {
        const { data } = await updateUsersOccasions({ accessToken, handleLogout }, payload);
        setProfile(data);
      } catch (err) {
        if (throwError) {
          throw err;
        }
      } finally {
        setLoading(false);
      }
    },
    [setLoading, setProfile, updateProfile, accessToken, handleLogout],
  );

  const handleLogin = useCallback(
    async (payload: LoginPayload, throwError = true): Promise<Profile | null> => {
      let result = null;
      setLoading(true);
      try {
        const { data } = await signIn(payload);
        const prof = await loadProfile(data.accessToken);
        handleSetAccessToken(data.accessToken);
        result = prof;
      } catch (err) {
        if (throwError) {
          throw err;
        }
      } finally {
        setLoading(false);
      }

      return result;
    },
    [setLoading, handleSetAccessToken, loadProfile],
  );

  const handleSignUp = useCallback(
    async (payload: LoginPayload, throwError = true): Promise<Profile | null> => {
      let result = null;
      setLoading(true);
      try {
        const { data } = await signUp(payload);
        const prof = await loadProfile(data.accessToken);
        handleSetAccessToken(data.accessToken);
        result = prof;
      } catch (err) {
        if (throwError) {
          throw err;
        }
      } finally {
        setLoading(false);
      }

      return result;
    },
    [setLoading, handleSetAccessToken, loadProfile],
  );

  const handleResetPassword = useCallback(
    async (payload: ResetPasswordPayload, throwError = true): Promise<Profile | null> => {
      let result = null;
      setLoading(true);
      try {
        const { data } = await resetPassword(payload);
        const prof = await loadProfile(data.accessToken);
        handleSetAccessToken(data.accessToken);
        result = prof;
      } catch (err) {
        if (throwError) {
          throw err;
        }
      } finally {
        setLoading(false);
      }

      return result;
    },
    [setLoading, handleSetAccessToken, loadProfile],
  );

  const sendResetPasswordEmail = useCallback(async (email: string, throwError = true): Promise<void> => {
    setLoading(true);
    try {
      await forgotPasswordEmail(email);
    } catch (err) {
      if (throwError) {
        throw err;
      }
    } finally {
      setLoading(false);
    }
  }, []);

  const handleLoginGuest = useCallback(
    async (throwError?: boolean): Promise<Profile | null> => {
      let result = null;
      setLoading(true);
      try {
        const { data } = await loginGuest();
        const prof = await loadProfile(data.accessToken);
        handleSetAccessToken(data.accessToken);
        result = prof;
      } catch (err) {
        if (throwError) {
          throw err;
        }
      } finally {
        setLoading(false);
      }

      return result;
    },
    [setLoading, handleSetAccessToken, loadProfile, loginGuest],
  );

  const handleGoogleLogin = useCallback(
    async (authCode: string, throwError = true): Promise<Profile | null> => {
      let result = null;
      setLoading(true);
      try {
        const { data } = await googleSignIn(authCode);
        const prof = await loadProfile(data.accessToken);
        handleSetAccessToken(data.accessToken);
        result = prof;
      } catch (err) {
        if (throwError) {
          throw err;
        }
      } finally {
        setLoading(false);
      }
      return result;
    },
    [loadProfile, handleSetAccessToken],
  );

  const handleFacebookLogin = useCallback(
    async (code: string, throwError = true): Promise<Profile | null> => {
      let result = null;
      setLoading(true);
      try {
        const { data } = await facebookSignIn(code);
        const prof = await loadProfile(data.accessToken);
        handleSetAccessToken(data.accessToken);
        result = prof;
      } catch (err) {
        if (throwError) {
          throw err;
        }
      } finally {
        setLoading(false);
      }
      return result;
    },
    [loadProfile, handleSetAccessToken],
  );

  const handleDeleteAccount = useCallback(
    async (token: string, throwError = true): Promise<null> => {
      setLoading(true);
      try {
        await deleteAccount({ accessToken: token, handleLogout });
        handleSetAccessToken(null);
      } catch (err) {
        if (throwError) {
          throw err;
        }
      } finally {
        setLoading(false);
      }

      return null;
    },
    [setLoading, handleSetAccessToken, loadProfile, loginGuest],
  );

  useEffect(() => {
    if (accessToken && !profile) {
      loadProfile(accessToken, false).finally(() => {
        setInitialized(true);
      });
    } else {
      setInitialized(true);
    }
  }, []);

  return {
    loading,
    initialized,
    accessToken,
    profile,
    handleLogin,
    handleSignUp,
    handleLoginGuest,
    handleGoogleLogin,
    handleLogout,
    handleUpdateProfile,
    sendResetPasswordEmail,
    handleResetPassword,
    handleDeleteAccount,
    handleUpdateUsersOccasions,
    handleFacebookLogin,
  };
};

export default useAuth;
