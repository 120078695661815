import React from 'react';
import {
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  Divider,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';

const Policy = () => {
  return (
    <Container maxWidth="lg" sx={{ marginTop: 4, marginBottom: 4 }}>
      <Typography variant="h4" gutterBottom align="center">
        Terms and Conditions
      </Typography>

      <Typography variant="body1" paragraph>
        Please read this Privacy and Cookies Policy carefully. This Privacy and Cookies Policy (Policy) applies between
        you, the User of this Service, and OFFRAPRENDAS UNIPESSOAL LDA, (Offra), the owner and provider of this Service.
        We take the privacy of your information very seriously. This Policy applies to our use of any and all Personal
        Data collected by us or provided by you in relation to your use of the Service.
      </Typography>
      <Typography variant="body1" paragraph>
        This Policy should be read alongside, and in addition to, our Terms of Use.
      </Typography>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Definitions and Interpretation</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" paragraph>
            In this Policy, the following definitions are used:
          </Typography>
          <List>
            <ListItem>
              <ListItemText
                primary="Cookies"
                secondary="A small text file placed on your computer by this Service when you visit certain parts of the Service and/or when you use certain features of the Service."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Data Protection Laws"
                secondary="Any applicable law relating to the processing of Personal Data, including but not limited to the Directive 96/46/EC (Personal Data Protection Directive), the Privacy Directive 2009/136/EC and the GDPR."
              />
            </ListItem>
            <ListItem>
              <ListItemText primary="GDPR" secondary="The General Personal Data Protection Regulation (EU) 2016/679." />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Personal Data"
                secondary="All personal information that you submit to us via the Service. This definition incorporates, where applicable, the definitions provided in the Data Protection Laws."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Platform"
                secondary="Our proprietary web domain offragift.com and all associated sub-domains."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="OFFRAPRENDAS UNIPESSOAL LDA"
                secondary="Incorporated and registered in Portugal, with company number 518303560."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Service"
                secondary="The provision to you of tailored gift ideas for your friends and family."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="User or you"
                secondary="Any third-party that accesses the Service and is not either (i) employed by OFFRAPRENDAS UNIPESSOAL LDA and acting in the course of their employment or (ii) engaged as a consultant or otherwise providing services to and accessing the Service in connection with the provision of such services. This includes visitors to our Platform, our clients and employees and representatives of our clients where applicable."
              />
            </ListItem>
          </List>
          <Typography variant="body1" paragraph>
            In this Policy, unless the context requires a different interpretation:
          </Typography>
          <List>
            <ListItem>
              <ListItemText primary="The singular includes the plural and vice versa." />
            </ListItem>
            <ListItem>
              <ListItemText primary="References to sub-clauses, clauses, schedules or appendices are to sub-clauses, clauses, schedules or appendices of this Policy." />
            </ListItem>
            <ListItem>
              <ListItemText primary="A reference to a person includes firms, companies, government entities, trusts and partnerships." />
            </ListItem>
            <ListItem>
              <ListItemText primary="'including' is understood to mean including without limitation" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Reference to any statutory provision includes any modification or amendment of it" />
            </ListItem>
            <ListItem>
              <ListItemText primary="The headings and sub-headings do not form part of this Policy." />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Scope of this Policy</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" paragraph>
            This Policy applies only to the actions of OFFRAPRENDAS UNIPESSOAL LDA and Users with respect to this
            Service. It does not extend to any websites of services that can be accessed from our Platform or any links
            we may provide to social media websites.
          </Typography>
          <Typography variant="body1" paragraph>
            Throughout the Platform, we may link to other websites owned and operated by certain trusted third parties
            to make additional services and services available to you, or provide external services. These other third
            party websites may also gather information about you in accordance with their own separate privacy policies.
            For privacy information relating to these other third party websites, please consult their privacy policies
            as appropriate.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Personal Data Collected</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" paragraph>
            We may collect the following Personal Data, which includes personal data, from you:
          </Typography>
          <List>
            <ListItem>
              <ListItemText primary="Name" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Information about the preferences of your proposed gift recipient" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Email address" />
            </ListItem>
            <ListItem>
              <ListItemText primary="IP address (automatically collected)" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Web browser type and version (automatically collected)" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Operating system (automatically collected)" />
            </ListItem>
            <ListItem>
              <ListItemText primary="A list of URLs starting with a referring site, your activity on this Service, and the site you exit to (automatically collected)" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Information about your interactions with the Platform and the Service (automatically collected); in each case, in accordance with this Policy." />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">How we collect Personal Data</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" paragraph>
            We collect Personal Data in the following ways:
          </Typography>
          <List>
            <ListItem>
              <ListItemText primary="Personal Data is given to us by you; and" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Personal Data is collected automatically." />
            </ListItem>
          </List>
          <Typography variant="body1" paragraph>
            We also collect, use and share Aggregated Data such as statistical or demographic data for any purpose.
            Aggregated Data could be derived from your Personal Data but is not considered Personal Data in law as this
            data will not directly or indirectly reveal your identity. For example, we may aggregate your usage data to
            calculate the percentage of users accessing a specific website feature. However, if we combine or connect
            Aggregated Data with your Personal Data so that it can directly or indirectly identify you, we treat the
            combined data as Personal Data which will be used in accordance with this Policy.
          </Typography>
          <Typography variant="body1" paragraph>
            We do not collect any Special Categories of Personal Data about you (this includes details about your race
            or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade
            union membership, information about your health, and genetic and biometric data). Nor do we collect any
            information about criminal convictions and offences.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Personal Data that is given to us by you</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" paragraph>
            We will collect your Personal Data in a number of ways, for example:
          </Typography>
          <List>
            <ListItem>
              <ListItemText primary="When you contact us via the Service, by e-mail or through any other means." />
            </ListItem>
            <ListItem>
              <ListItemText primary="When you register with us and set up an account to receive the Service." />
            </ListItem>
            <ListItem>
              <ListItemText primary="When you complete surveys that we use for research purposes (although you are not obliged to respond to them)." />
            </ListItem>
            <ListItem>
              <ListItemText primary="When you enter a competition or promotion through a social media channel." />
            </ListItem>
            <ListItem>
              <ListItemText primary="When you elect to receive marketing communications from us." />
            </ListItem>
            <ListItem>
              <ListItemText primary="When you use the Service." />
            </ListItem>
          </List>
          <Typography variant="body1" paragraph>
            In each case, in accordance with this Policy.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Personal Data that is collected automatically</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" paragraph>
            To the extent that you access the Service, we will collect your Personal Data automatically, for example:
          </Typography>
          <List>
            <ListItem>
              <ListItemText primary="We automatically collect some information about your use of the Service. This information helps us to make improvements to content and navigation and includes your IP address, the date, times and frequency with which you access the Service and the way you use and interact with its content." />
            </ListItem>
          </List>
          <Typography variant="body1" paragraph>
            We will collect your Personal Data automatically using tracking technologies. For more information about
            these tracking technologies, and how we use them on the Service, see the section below, headed
            &quot;Cookies&quot; and our Cookie Schedule.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Our use of Personal Data</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" paragraph>
            Any or all of the above Personal Data may be required by us from time to time in order to provide you with
            the best possible service and experience when using our Platform and Service. Specifically, Personal Data
            may be used by us for the following reasons:
          </Typography>
          <List>
            <ListItem>
              <ListItemText primary="Internal record keeping." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Improvement of our Service." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Transmission by email of marketing materials that may be of interest to you." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Contact for market research purposes which may be done using email. Such information may be used to customise or update the Service." />
            </ListItem>
          </List>
          <Typography variant="body1" paragraph>
            In each case, in accordance with this Policy.
          </Typography>
          <Typography variant="body1" paragraph>
            We may use your Personal Data for the above purposes if we deem it necessary to do so for our legitimate
            interests. If you are not satisfied with this, you have the right to object in certain circumstances (see
            the section headed &quot;Your rights&quot; below).
          </Typography>
          <Typography variant="body1" paragraph>
            For the delivery of direct marketing to you via e-mail, depending on your location, we may need your
            consent, whether via an opt-in or soft-opt-in:
          </Typography>
          <List>
            <ListItem>
              <ListItemText primary="Soft opt-in consent is a specific type of consent which applies when you have previously engaged with us (for example, you contact us to ask us for more details about a particular product/service, and we are marketing similar products/services). Under 'soft opt-in' consent, we will take your consent as given unless you opt-out." />
            </ListItem>
            <ListItem>
              <ListItemText primary="For other types of e-marketing, we are required to obtain your explicit consent; that is, you need to take positive and affirmative action when consenting by, for example, checking a tick box that we will provide." />
            </ListItem>
          </List>
          <Typography variant="body1" paragraph>
            If you are not satisfied with our approach to marketing, you have the right to withdraw consent at any time.
            To find out how to withdraw your consent, see the section headed &quot;Your rights&quot; below.
          </Typography>
          <Typography variant="body1" paragraph>
            When you register with us to use our Service, the legal basis for this processing is the performance of a
            contract between you and us and/or taking steps, at your request, to enter into such a contract.
          </Typography>
          <Typography variant="body1" paragraph>
            We may use your Personal Data to show you adverts and promotions from appropriate service providers as well
            as other content on other websites. If you do not want us to use your Personal Data to show you adverts and
            promotions from appropriate service providers as well as content on other websites, please turn off the
            relevant cookies as detailed in our Cookie Schedule.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Who we share Personal Data with</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" paragraph>
            We may share your Personal Data with the following groups of people for the following reasons:
          </Typography>
          <List>
            <ListItem>
              <ListItemText primary="An artificial intelligence language model in order to provide the Service to you. We use Open AI to tailor gift ideas. For more information on how Open AI processes Personal Data, please review the Open AI Privacy Policy." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Our employees, agents and/or professional advisors in order to obtain advice." />
            </ListItem>
            <ListItem>
              <ListItemText primary="With Amazon in order to present you with options to purchase your suggested gifts. For more information on how Amazon processes Personal Data, please review the Amazon Privacy Policy." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Third-party service providers who process personal data to provide analytics which help us ensure the Service runs smoothly and optimise and customise the Service for you." />
            </ListItem>
          </List>
          <Typography variant="body1" paragraph>
            In each case, in accordance with this Policy.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Keeping Personal Data Secure</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" paragraph>
            We will use technical and organisational measures to safeguard your Personal Data, for example:
          </Typography>
          <List>
            <ListItem>
              <ListItemText primary="Access to your account is controlled by a password and a user name that is unique to you." />
            </ListItem>
            <ListItem>
              <ListItemText primary="We store your Personal Data on secure servers." />
            </ListItem>
          </List>
          <Typography variant="body1" paragraph>
            Technical and organisational measures include measures to deal with any suspected data breach. If you
            suspect any misuse or loss or unauthorised access to your Personal Data, please let us know immediately by
            contacting us.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Personal Data Retention</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" paragraph>
            Unless a longer retention period is required or permitted by law, we will only hold your Personal Data on
            our systems for the period necessary to fulfil the purposes outlined in this Policy or until you request
            that the Personal Data be deleted.
          </Typography>
          <Typography variant="body1" paragraph>
            However, please note that even if we delete your Personal Data, it may persist on backup or archival media
            for legal, tax or regulatory purposes or we may retain anonymised records of your historical use of the
            Service for analytics purposes.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Your Rights</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" paragraph>
            You have the following rights in relation to your Personal Data:
          </Typography>
          <List>
            <ListItem>
              <ListItemText primary="Right of access - the right to request (i) copies of the information we hold about you at any time, or (ii) that we modify, update or delete such information. If we provide you with access to the information we hold about you, we will not charge you for this, unless your request is 'manifestly unfounded or excessive.' Where we are legally permitted to do so, we may refuse your request. If we refuse your request, we will tell you the reasons why." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Right of correction - the right to have your Personal Data rectified if it is inaccurate or incomplete." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Right to erase - the right to request that we delete or remove your Personal Data from our systems." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Right to restrict our use of your Personal Data - the right to 'block' us from using your Personal Data or limit the way in which we can use it." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Right to data portability - the right to request that we move, copy or transfer your Personal Data." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Right to object - the right to object to our use of your Personal Data including where we use it for our legitimate interests." />
            </ListItem>
          </List>
          <Typography variant="body1" paragraph>
            To make enquiries, exercise any of your rights set out above, or withdraw your consent to the processing of
            your Personal Data (where consent is our legal basis for processing your Personal Data), please contact us
            via <a href="mailto:contact@offra.eu">contact@offra.eu</a>. We will endeavour to respond to your request as
            soon as is practicable or within a reasonable timeframe.
          </Typography>
          <Typography variant="body1" paragraph>
            If you are not satisfied with the way a complaint you make in relation to your Personal Data is handled by
            us, you may be able to refer your complaint to the relevant data protection authority.
          </Typography>
          <Typography variant="body1" paragraph>
            It is important that the Personal Data we hold about you is accurate and current. Please keep us informed if
            your Personal Data changes during the period for which we hold it.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Transfers to third countries (EEA residents)</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" paragraph>
            This clause only applies to residents of the EEA or countries that follow the GDPR regime.
          </Typography>
          <Typography variant="body1" paragraph>
            We will only transfer Personal Data outside the EEA where it is compliant with data protection legislation
            and the means of transfer provides adequate safeguards in relation to your data, for example by way of
            Standard Contractual Clauses adopted by the European Commission.
          </Typography>
          <Typography variant="body1" paragraph>
            To ensure that your Personal Data receives an adequate level of protection, we have put in place appropriate
            safeguards and procedures with the third-parties we share your Personal Data with. This ensures your
            Personal Data is treated by those third parties in a way that is consistent with the Data Protection Laws.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Changes of business ownership and control</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" paragraph>
            We may, from time to time, expand or reduce its business and this may involve the sale and/or the transfer
            of control of all or part of OFFRAPRENDAS UNIPESSOAL LDA. Personal Data provided by Users will, where it is
            relevant to any part of our business so transferred, be transferred along with that part and the new owner
            or newly controlling party will, under the terms of this Policy, be permitted to use the Personal Data for
            the purposes for which it was originally supplied to us.
          </Typography>
          <Typography variant="body1" paragraph>
            We may also disclose Personal Data to a prospective purchaser of our business or any part of it.
          </Typography>
          <Typography variant="body1" paragraph>
            In the above instances, we will take steps with the aim of ensuring your privacy is protected.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Cookies</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" paragraph>
            This Platform may place and access certain Cookies on your computer. We use Cookies to improve your
            experience of using the Service and to improve our range of services. We have carefully chosen these Cookies
            and have taken steps to ensure that your privacy is protected and respected at all times.
          </Typography>
          <Typography variant="body1" paragraph>
            All Cookies used by the Platform are used in accordance with the Data Protection Laws.
          </Typography>
          <Typography variant="body1" paragraph>
            Before the Platform places Cookies on your computer, you will be presented with a message bar requesting
            your consent to set those Cookies. By giving your consent to the placing of Cookies, you are enabling us to
            provide a better experience and service to you. You may, if you wish, deny consent to the placing of
            Cookies; however certain features of the Platform may not function fully or as intended.
          </Typography>
          <Typography variant="body1" paragraph>
            You can find a list of Cookies that we use in the Cookie Schedule.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Changes to this Policy</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" paragraph>
            We reserve the right to change this Policy as we may deem necessary from time to time or as may be required
            by law. Any changes will be immediately posted on the Platform and you are deemed to have accepted the terms
            of the Policy on your first use of the Platform following the alterations.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Your Rights</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" paragraph>
            You have the following rights in relation to your Personal Data:
          </Typography>
          <List>
            <ListItem>
              <ListItemText primary="Right of access - the right to request (i) copies of the information we hold about you at any time, or (ii) that we modify, update or delete such information." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Right of correction - the right to have your Personal Data rectified if it is inaccurate or incomplete." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Right to erase - the right to request that we delete or remove your Personal Data from our systems." />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Cookie Schedule</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="h6" gutterBottom>
            What are Cookies?
          </Typography>
          <Typography variant="body1" paragraph>
            Cookies are pieces of information stored directly on the computer that you are using. Cookies allow us to
            collect information such as browser type, time spent using our Service, pages visited, language preferences,
            and other web traffic data. We, and our service providers, use the information for security purposes, to
            facilitate online navigation, to display information more effectively, to personalise your experience while
            using the Service, and to otherwise analyse user activity.
          </Typography>
          <Typography variant="body1" paragraph>
            We can recognise your computer to assist your use of the Service. We also gather statistical information
            about the usage of the Service in order to continually improve its design and functionality, understand how
            the Service is used, and assist us with resolving questions regarding the Service. Cookies further allow us
            to select which of our offers are most likely to appeal to you and to display them to you. We may also use
            cookies to see how you interact with our offers, and we may use cookies or other files to understand your
            use of our Website.
          </Typography>
          <Typography variant="body1" paragraph>
            Cookies on our site are saved for 30 days to enhance your browsing experience and remember your preferences.
          </Typography>

          <Typography variant="h6" gutterBottom>
            How we use Cookies?
          </Typography>
          <Typography variant="body1" paragraph>
            We use cookies for a variety of reasons detailed below. Unfortunately, in most cases there are no industry
            standard options for disabling cookies without completely disabling the functionality and features they add
            to this Website. It is recommended that you leave on all cookies if you are not sure whether you need them
            or not in case they are used to provide a service that you use.
          </Typography>

          <Typography variant="h6" gutterBottom>
            How to disable Cookies
          </Typography>
          <Typography variant="body1" paragraph>
            If you do not want information collected through the use of cookies when using your user account or our
            Website, you will be given the choice to accept cookies when you first visit the Website. In addition, there
            is a simple procedure in most browsers that allows you to automatically decline cookies or gives you the
            choice of declining or accepting the transfer to your computer of a particular cookie (or cookies) from a
            particular site. You may also wish to refer to{' '}
            <Link
              to="http://www.allaboutcookies.org/manage-cookies/index.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              this guide
            </Link>
            .
          </Typography>
          <Typography variant="body1" paragraph>
            However, if you do not accept these cookies, you may experience some inconvenience in your use of the
            Service. For example, we may not be able to recognise your computer, and you may need to log in every time
            you use the Website or Platform.
          </Typography>

          <Typography variant="h6" gutterBottom>
            The Cookies We Set
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Account-related Cookies
          </Typography>
          <Typography variant="body1" paragraph>
            If you register an account with us, we will use cookies to manage the signup process and general
            administration. These cookies will be deleted when you log out.
          </Typography>

          <Typography variant="subtitle1" gutterBottom>
            Login-related Cookies
          </Typography>
          <Typography variant="body1" paragraph>
            To prevent you from having to log in every single time you visit us, we use cookies to remember your login
            state. These cookies are deleted when you log out to ensure that other users of your machine cannot access
            your private data.
          </Typography>

          <Typography variant="subtitle1" gutterBottom>
            Third-Party Cookies
          </Typography>
          <Typography variant="body1" paragraph>
            In some special cases, we also use cookies provided by trusted third parties. The following section details
            which third-party cookies you might encounter through this site:
          </Typography>
          <List>
            <ListItem>
              <ListItemText
                primary="Google Analytics"
                secondary="This site uses Google Analytics, a common and trusted tool that helps website owners measure how users interact with website content. These cookies may track things such as how long you spend on the site and the pages you visit so we can continue to produce engaging content."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Testing New Features"
                secondary="From time to time, we test new features and make subtle changes to the way the site is delivered. When we are still testing new features, these cookies may be used to ensure that you receive a consistent experience whilst on the site and to understand which optimisations our users appreciate the most."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Social Media Plugins"
                secondary="We also use social media buttons and/or plugins on this site that allow you to connect with your social network in various ways. For these to work, social media sites like Facebook, Instagram, and Twitter will set cookies through our site. These cookies may be used to enhance your profile on their site or contribute to the data they hold for various purposes outlined in their respective privacy policies."
              />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>

      <Divider sx={{ marginY: 4 }} />
      <Typography variant="body2" align="center">
        © {new Date().getFullYear()} OFFRAPRENDAS UNIPESSOAL LDA. All Rights Reserved.
      </Typography>
    </Container>
  );
};

export default Policy;
