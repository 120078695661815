import React, { useState, forwardRef, ForwardedRef, Dispatch } from 'react';
import { TextFieldProps } from '@mui/material/TextField';
import { Box } from '@mui/material';
import Input from '../ui-kit/Input';
import Button from '../ui-kit/Button';

const CloseButton = ({
  setOpen,
  newHobby,
  setNewHobby,
  onAddHobby,
  label = 'Add custom event',
  disabled = false,
}: {
  setOpen: Dispatch<boolean>;
  newHobby: string;
  setNewHobby: Dispatch<string>;
  onAddHobby: () => void;
  label?: string;
  disabled: boolean;
}) => (
  <Box
    className="flex gap-2 justify-between px-5 py-3 bg-yellow-main shadow-[0_-1px_3px_-1px_rgba(0,0,0,0.2)] sticky bottom-0 left-o right-0 items-center"
    onMouseDown={e => e.stopPropagation()}
  >
    <Input
      type="text"
      value={newHobby}
      placeholder={label}
      disabled={disabled}
      onChange={e => setNewHobby(e.target.value)}
      inputProps={{
        style: {
          padding: '9px 10px',
        },
      }}
      onKeyDown={e => {
        e.stopPropagation();
        if (e.key === 'Enter') {
          e.preventDefault();
          onAddHobby();
        }
      }}
    />
    <Button
      sx={{ backgroundColor: '#fff', textTransform: 'initial' }}
      type="button"
      disabled={disabled}
      onClick={() => {
        onAddHobby();
        setOpen(false);
      }}
    >
      Add & Close
    </Button>
  </Box>
);

const SelectWithInput = forwardRef(
  (
    {
      SelectProps,
      children,
      onAddHobby,
      validateHobbies,
      customInputPlaceholder,
      disabled,
      ...props
    }: TextFieldProps & {
      onAddHobby: (hobby: string) => void;
      validateHobbies?: () => void;
      customInputPlaceholder?: string;
    },
    ref?: ForwardedRef<any>,
  ) => {
    const [open, setOpen] = useState(false);
    const [newHobby, setNewHobby] = useState('');

    const handleAddHobby = () => {
      if (newHobby.trim()) {
        onAddHobby(newHobby.trim());
        setNewHobby('');
      }

      if (validateHobbies) {
        validateHobbies();
      }
    };

    const handleDropdownClose = async () => {
      setOpen(false);
      if (validateHobbies) {
        validateHobbies();
      }
    };

    return (
      <Input
        ref={ref}
        {...props}
        SelectProps={{
          ...SelectProps,
          open,
          MenuProps: {
            ...SelectProps?.MenuProps,
            MenuListProps: {
              ...SelectProps?.MenuProps?.MenuListProps,
              style: { ...SelectProps?.MenuProps?.MenuListProps?.style, paddingBottom: 0 },
            },
          },
          onOpen: (e: React.SyntheticEvent<Element, Event>) => {
            setOpen(true);
            if (typeof SelectProps?.onOpen === 'function') {
              SelectProps.onOpen(e);
            }
          },
          onClose: (e: React.SyntheticEvent<Element, Event>) => {
            setOpen(false);
            if (typeof SelectProps?.onClose === 'function') {
              SelectProps.onClose(e);
            }
          },
        }}
      >
        {Array.isArray(children)
          ? [
              ...children,
              <CloseButton
                disabled={!!disabled}
                key="button"
                setOpen={setOpen}
                newHobby={newHobby}
                setNewHobby={setNewHobby}
                label={customInputPlaceholder}
                onAddHobby={async () => {
                  await handleAddHobby();
                  await handleDropdownClose();
                }}
              />,
            ]
          : [
              children,
              <CloseButton
                disabled={!!disabled}
                key="button"
                setOpen={setOpen}
                newHobby={newHobby}
                setNewHobby={setNewHobby}
                label={customInputPlaceholder}
                onAddHobby={async () => {
                  await handleAddHobby();
                  await handleDropdownClose();
                }}
              />,
            ]}
      </Input>
    );
  },
);

export default SelectWithInput;
