import React, { useState } from 'react';
import { Typography } from '@mui/material';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';

import Button from '../ui-kit/Button';
import IconButton from '../ui-kit/Button/IconButton';
import Dialog, { DialogActions, DialogContent, DialogContentText, DialogTitle } from '../ui-kit/Dialog';

const Footer: React.FC = () => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handlePolicyClick = () => {
    window.open('/policy', '_blank');
  };

  return (
    <div className="flex flex-col justify-center items-center gap-2 pt-5 [text-shadow:_0_0px_10px_rgb(255_255_255_/_70%)] mt-auto">
      <Button
        variant="text"
        color="secondary"
        className="!text-blue-light !font-normal !normal-case !text-[16px] [text-shadow:_0_0px_10px_rgb(255_255_255_/_70%)]"
        onClick={handlePolicyClick}
      >
        Terms and conditions
      </Button>
      <div>
        <Typography className="text-center">
          Need support or have a request? Contact us at:{' '}
          <a href="mailto:contact@offra.eu" className="text-blue-light">
            contact@offra.eu
          </a>
        </Typography>
      </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle className="flex items-center justify-between">
          Terms and conditions
          <IconButton onClick={handleClose}>
            <ClearRoundedIcon fontSize="large" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <span className="block w-[60vw] max-w-full">Coming soon...</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="!pb-5">
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Footer;
