import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Logo from '../../components/ui-kit/Logo';

const InvitationPage = () => {
  const { inviterId } = useParams<{ inviterId: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    if (!inviterId) {
      navigate('/');
      return;
    }

    localStorage.setItem('inviterId', inviterId);

    navigate('/login');
  }, [inviterId, navigate]);

  return (
    <div className="relative min-h-[100vh]">
      <div className="container relative z-10">
        <div className="relative z-10 min-h-[100vh] flex flex-col justify-between">
          <div className="pt-10 px-5">
            <Logo color="primary" />
          </div>
          <div className="py-10 flex flex-col items-center justify-center">Processing your invitation...</div>
        </div>
      </div>
    </div>
  );
};

export default InvitationPage;
