import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Alert, Button } from '@mui/material';
import { AxiosError } from 'axios';

import { ReactComponent as LogoIcon } from '../../assets/present.svg';
import GoBackButton from '../../components/GoBackButton';
import Input from '../../components/ui-kit/Input';
import { useAuth } from '../../providers/auth';
import Modal from '../../components/Modal';

const ForgotPassword = () => {
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const [generalError, setGeneralError] = useState<string | string[]>('');
  const { sendResetPasswordEmail } = useAuth();

  const formik = useFormik({
    initialValues: { email: '' },
    validationSchema: Yup.object({
      email: Yup.string().email('Please enter a valid email address').required('Email is required'),
    }),
    onSubmit: async values => {
      try {
        await sendResetPasswordEmail(values.email.toLowerCase());
        return setIsModalOpened(true);
      } catch (error) {
        if (error instanceof AxiosError && error?.response?.data.statusCode === 404) {
          return setGeneralError('Please enter a valid email address');
        }

        return setGeneralError('Could not sent the letter');
      }
    },
  });

  return (
    <div className="relative min-h-[100vh] bg-grey-main">
      <div className="container relative z-10 py-10">
        <GoBackButton />

        <div className="relative z-10 min-h-[100vh] flex flex-col">
          <div className="flex justify-center items-center">
            <LogoIcon />
          </div>

          <div className="flex flex-col items-center px-5 mt-5">
            <p className="text-yellow-main text-2xl font-semibold mb-10">Forgot Password?</p>
            <p className="mt-2 mb-6 text-white">Enter your email address and we will send you a reset link.</p>

            <form onSubmit={formik.handleSubmit} className="flex flex-col w-full gap-6">
              {!!generalError && (
                <div className="pb-5">
                  <Alert severity="error">{Array.isArray(generalError) ? generalError.at(-1) : generalError}</Alert>
                </div>
              )}

              <Input
                fullWidth
                name="email"
                type="email"
                placeholder="Enter your email address"
                value={formik.values.email}
                error={formik.touched.email && !!formik.errors.email}
                helperText={formik.touched.email && formik.errors.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />

              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!formik.isValid || !formik.dirty}
                fullWidth
              >
                Continue
              </Button>
            </form>
          </div>
        </div>
      </div>

      <Modal
        open={isModalOpened}
        onClose={() => setIsModalOpened(false)}
        titleColor="#2FAE94"
        footer={
          <Button
            type="button"
            variant="text"
            onClick={() => setIsModalOpened(false)}
            sx={{ color: '#2FAE94', textTransform: 'initial' }}
          >
            Ok
          </Button>
        }
      >
        <p className="text-green-main font-semibold text-center">
          A password reset link was successfully sent to your email
        </p>
      </Modal>
    </div>
  );
};

export default ForgotPassword;
