import { useState } from 'react';
import { useAuth } from '../../providers/auth';
import { updateNotificationSettings, NotificationSettings } from './api';

export interface NotificationSettingsHook {
  loading: boolean;
  handleUpdate: (newSettings: NotificationSettings) => Promise<void>;
}

const useNotificationSettings = (): NotificationSettingsHook => {
  const auth = useAuth();

  const [loading, setLoading] = useState<boolean>(false);

  const handleUpdate = async (newSettings: NotificationSettings) => {
    setLoading(true);
    try {
      await updateNotificationSettings(auth, newSettings);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error updating notification settings:', error);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    handleUpdate,
  };
};

export default useNotificationSettings;
