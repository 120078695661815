import React from 'react';

interface TypographyProps {
  children: React.ReactNode;
  variant?: 'h2' | 'h3' | 'h4' | 'body';
  className?: string;
}

const styles = {
  h2: `text-3xl font-bold text-green-main`,
  h3: `text-2xl font-semibold text-green-main`,
  h4: `text-xl font-medium text-green-main`,
  body: `text-base text-green-main`,
};

const Typography: React.FC<TypographyProps> = ({ children, variant = 'body', className = '', ...props }) => {
  const Tag = variant;

  return (
    <Tag className={`${styles[variant]} ${className} `} {...props}>
      {children}
    </Tag>
  );
};

export default Typography;
