import React, { useState } from 'react';

import { Accordion, AccordionSummary, AccordionDetails, List } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NotificationsIcon from '@mui/icons-material/Notifications';

import NotificationToggle from '../NotificationToggle';
import useNotificationSettings from '../../../data-hooks/push-notifications/useNotificationSettings';
import { NotificationSettings } from '../../../data-hooks/push-notifications/api';

interface NotificationsAccordionProps {
  isOpen: boolean;
  onToggle: () => void;
  settings: NotificationSettings;
}

const NotificationsAccordion: React.FC<NotificationsAccordionProps> = ({ isOpen, onToggle, settings }) => {
  const { handleUpdate, loading } = useNotificationSettings();

  const [notifications, setNotifications] = useState(settings);

  const handleToggle = (key: keyof typeof settings) => async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSettings = {
      ...notifications,
      [key]: event.target.checked,
    };

    setNotifications(newSettings);
    await handleUpdate(newSettings);
  };

  return (
    <Accordion
      disableGutters
      expanded={isOpen}
      sx={{
        boxShadow: 'none',
        backgroundColor: 'transparent',
        border: 'none',
        '&::before': {
          display: 'none',
        },
      }}
    >
      <AccordionSummary
        sx={{
          backgroundColor: 'transparent',
          boxShadow: 'none',
          padding: 0,
          display: 'inline-flex',
          justifyContent: 'flex-start',
          '&:hover': { backgroundColor: 'transparent' },
          '&.Mui-expanded': { minHeight: 'auto' },
          '& .MuiAccordionSummary-content': {
            alignItems: 'center',
          },
        }}
        expandIcon={<ExpandMoreIcon sx={{ color: 'inherit' }} />}
        onClick={onToggle}
      >
        <NotificationsIcon sx={{ marginRight: 4, width: '20px', height: '20px' }} />
        <p className="font-semibold">Notifications</p>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0, pl: 8 }}>
        <List component="div" disablePadding sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <NotificationToggle
            label="Global Annual Occasions"
            checked={notifications.globalOccasionsNotificationsEnabled}
            onChange={handleToggle('globalOccasionsNotificationsEnabled')}
            isLoading={loading}
          />
          <NotificationToggle
            label="Private Occasions"
            checked={notifications.privateOccasionsNotificationsEnabled}
            onChange={handleToggle('privateOccasionsNotificationsEnabled')}
            isLoading={loading}
          />
          <NotificationToggle
            label="Email Notifications"
            checked={notifications.emailNotificationsEnabled}
            onChange={handleToggle('emailNotificationsEnabled')}
            isLoading={loading}
          />
          <NotificationToggle
            label="Push-on Notifications"
            checked={notifications.pushNotificationsEnabled}
            onChange={handleToggle('pushNotificationsEnabled')}
            isLoading={loading}
          />
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

export default NotificationsAccordion;
