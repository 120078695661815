import React from 'react';
import { ListItem, ListItemText, Switch } from '@mui/material';

interface NotificationToggleProps {
  label: string;
  checked: boolean;
  onChange: any;
  isLoading: boolean;
}

const NotificationToggle: React.FC<NotificationToggleProps> = ({ label, checked, onChange, isLoading }) => {
  return (
    <ListItem sx={{ display: 'flex', justifyContent: 'space-between', padding: 0 }}>
      <ListItemText primary={label} />
      <Switch checked={checked} onChange={onChange} disabled={isLoading} />
    </ListItem>
  );
};

export default NotificationToggle;
