import React, { useContext, PropsWithChildren, useMemo } from 'react';
import useAuthHook, { AuthHook } from '../data-hooks/auth/useAuth';

export interface Auth extends AuthHook {}

const initialState: Auth = {
  loading: false,
  accessToken: null,
  profile: null,
  initialized: false,
  handleLogin: async () => {
    throw new Error('Not implemented');
  },
  handleSignUp: async () => {
    throw new Error('Not implemented');
  },
  handleLoginGuest: async () => {
    throw new Error('Not implemented');
  },
  handleGoogleLogin: async () => {
    throw new Error('Not implemented');
  },
  handleFacebookLogin: async () => {
    throw new Error('Not implemented');
  },
  handleLogout: async () => {
    throw new Error('Not implemented');
  },
  handleUpdateProfile: async () => {
    throw new Error('Not implemented');
  },
  handleUpdateUsersOccasions: async () => {
    throw new Error('Not implemented');
  },
  sendResetPasswordEmail: async () => {
    throw new Error('Not implemented');
  },
  handleResetPassword: async () => {
    throw new Error('Not implemented');
  },
  handleDeleteAccount: async () => {
    throw new Error('Not implemented');
  },
};

export const AuthContext = React.createContext(initialState);
export const useAuth = (): Auth => useContext(AuthContext);

const AuthProvider: React.FC<PropsWithChildren> = ({ children }: PropsWithChildren) => {
  const {
    accessToken,
    profile,
    loading,
    initialized,
    handleLogin,
    handleSignUp,
    handleLoginGuest,
    handleLogout,
    handleUpdateProfile,
    handleGoogleLogin,
    sendResetPasswordEmail,
    handleResetPassword,
    handleDeleteAccount,
    handleUpdateUsersOccasions,
    handleFacebookLogin,
  } = useAuthHook();

  const value: Auth = useMemo(
    () => ({
      accessToken,
      loading,
      initialized,
      profile,
      handleLogout,
      handleLoginGuest,
      handleGoogleLogin,
      handleLogin,
      handleSignUp,
      handleUpdateProfile,
      sendResetPasswordEmail,
      handleResetPassword,
      handleDeleteAccount,
      handleUpdateUsersOccasions,
      handleFacebookLogin,
    }),
    [accessToken, loading, profile, initialized, handleLogin, handleLoginGuest, handleLogout, handleUpdateProfile],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
