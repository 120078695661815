import { Avatar, Box, Typography } from '@mui/material';
import React from 'react';

interface ActionButtonProps {
  action: () => void;
  label: string;
  icon: React.ReactElement;
}

const MAX_WIDTH = '80px';

const ActionButton: React.FC<ActionButtonProps> = ({ action, icon, label, ...props }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: MAX_WIDTH,
        alignItems: 'center',
        gap: 1,
      }}
    >
      <Avatar
        onClick={action}
        {...props}
        sx={{ width: MAX_WIDTH, height: MAX_WIDTH, bgcolor: '#009F93', borderRadius: 4, cursor: 'pointer' }}
      >
        {icon}
      </Avatar>
      <Typography variant="body2" sx={{ textAlign: 'center', color: '#ffffffe2', fontWeight: 500 }}>
        {label}
      </Typography>
    </Box>
  );
};

export default ActionButton;
