import { API_BASE_URL } from '../../config/api';
import { fetchWithAuth, Auth } from '../../utils/fetch';
import Contact, { ContactCreateDTO } from '../../interfaces/Contact';
import { OccasionPayload } from '../auth/api';

export const getContacts = (auth: Auth) =>
  fetchWithAuth<Contact[]>(auth, `${API_BASE_URL}/contacts`, { method: 'GET' });

export const getContactById = (auth: Auth, id: string) =>
  fetchWithAuth<Contact>(auth, `${API_BASE_URL}/contacts/${id}`, { method: 'GET' });

export const createContact = (auth: Auth, data: ContactCreateDTO) =>
  fetchWithAuth<Contact>(auth, `${API_BASE_URL}/contacts`, { method: 'POST', data });

export const updateContact = (auth: Auth, id: string, data: ContactCreateDTO) =>
  fetchWithAuth<Contact>(auth, `${API_BASE_URL}/contacts/${id}`, { method: 'PUT', data });

export const deleteContacts = (auth: Auth, id: string) =>
  fetchWithAuth<boolean>(auth, `${API_BASE_URL}/contacts/${id}`, { method: 'DELETE' });

export const updateProfileViaInvite = (auth: Auth, inviterId: string) =>
  fetchWithAuth<Contact>(auth, `${API_BASE_URL}/contacts/${inviterId}/accept`, { method: 'POST' });

export const updateContactsOcassions = (auth: Auth, id: string, data: OccasionPayload) =>
  fetchWithAuth<Contact>(auth, `${API_BASE_URL}/contacts/${id}/occasions`, {
    method: 'PUT',
    data,
  });
