import React from 'react';
import { FacebookProvider, LoginButton } from 'react-facebook';

import { ReactComponent as FacebookIcon } from '../../assets/facebook.svg';
import { FACEBOOK_API_KEY } from '../../config/api';

interface FacebookLoginButtonProps {
  loginCallback: (response: any) => void;
  setGeneralError: React.Dispatch<React.SetStateAction<string | string[]>>;
}

const FacebookLoginButton: React.FC<FacebookLoginButtonProps> = ({ loginCallback, setGeneralError }) => {
  const responseFacebook = (response: any) => {
    if (response.authResponse && response.authResponse.accessToken) {
      loginCallback(response.authResponse.accessToken);
    } else {
      setGeneralError('Facebook login failed.');
    }
  };

  return (
    <div className="flex items-center justify-center w-12 h-12 bg-white rounded-full shadow-md relative overflow-hidden">
      <FacebookProvider appId={FACEBOOK_API_KEY!}>
        <LoginButton scope="email" onSuccess={responseFacebook}>
          <FacebookIcon className="w-full h-full scale-[275%]" />
        </LoginButton>
      </FacebookProvider>
    </div>
  );
};

export default FacebookLoginButton;
