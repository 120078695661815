import React from 'react';
import { Alert, CircularProgress } from '@mui/material';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import { FormikErrors, FormikTouched, FieldArray, FormikProvider, FormikProps } from 'formik';
import dayjs, { Dayjs } from 'dayjs';
import * as Yup from 'yup';

import dateMask from '../../utils/date';
import { DropdownsData } from '../../data-hooks/profile-dropdowns/api';
import Input, { selectRenderValue } from '../ui-kit/Input';
import Select from '../ui-kit/Select';
import Button from '../ui-kit/Button';
import MenuItem from '../ui-kit/MenuItem';
import DatePicker, { getActionBar } from '../ui-kit/DatePicker';
import OccasionFormFields, { OccasionValue } from './OccasionFormFields';
import SelectWithInput from '../SelectWithInput';

export interface ProfileValues {
  name: string;
  gender: string;
  birthday: Dayjs | null;
  country: string;
  hobbiesAndInterests: string[];
  customHobbiesAndInterests?: string[];
  occasions: OccasionValue[];
}

export interface Props {
  title: string;
  values: ProfileValues;
  touched: FormikTouched<ProfileValues>;
  errors: FormikErrors<ProfileValues>;
  loading: boolean;
  isValid: boolean;
  formikState: FormikProps<ProfileValues>;
  generalError: string;
  submitBtnLabel?: string;
  dropdowns: DropdownsData;
  showOccasionFields?: boolean;
  isConnectedUser?: boolean;
  handleChange(e: React.ChangeEvent<any>): void;
  handleBlur(e: React.FocusEvent<any, Element>): void;
  handleSubmit(e?: React.FormEvent<HTMLFormElement>): void;
  setDropdowns?: React.Dispatch<React.SetStateAction<DropdownsData>>;
}

const hobbiesAndInterestsLength = {
  MIN: 1,
  MAX: 10,
};

export const profileFormValidators = {
  name: Yup.string()
    .trim()
    .required('Name is a required field')
    .min(3, ({ min }) => `Name must be longer than or equal to ${min} characters`),
  gender: Yup.string().required('Gender is a required field'),
  birthday: Yup.object().required('Birthday is a required field'),
  country: Yup.string().required('Country is a required field'),
  hobbiesAndInterests: Yup.array()
    .of(Yup.string())
    .min(hobbiesAndInterestsLength.MIN, `Please select at least ${hobbiesAndInterestsLength.MIN} interest`)
    .max(hobbiesAndInterestsLength.MAX, `You can select up to ${hobbiesAndInterestsLength.MAX} interests`)
    .required('Hobbies and Interests is a required field'),
  occasions: Yup.array().of(
    Yup.object()
      .shape({
        label: Yup.string(),
        date: Yup.object().nullable(),
      })
      .test((value, context) => {
        if ((value.label && value.date) || (!value.label && !value.date)) {
          return true;
        }

        if (!value.label) {
          return new Yup.ValidationError('Occasion Type is a required field', value.date, `${context.path}.label`);
        }
        if (!value.date) {
          return new Yup.ValidationError('Occasion Date is a required field', value.date, `${context.path}.date`);
        }

        return true;
      }),
  ),
};

const ProfileForm: React.FC<Props> = (props: Props) => {
  const {
    title,
    values,
    touched,
    errors,
    loading,
    isValid,
    formikState,
    generalError,
    dropdowns,
    submitBtnLabel = 'Save',
    showOccasionFields = true,
    isConnectedUser = false,
    handleSubmit,
    handleChange,
    handleBlur,
    setDropdowns,
  } = props;
  const { gender: genders, countries, occasions, hobbiesAndInterests } = dropdowns;

  const handleAddHobby = (newHobby: string) => {
    if (!hobbiesAndInterests.includes(newHobby)) {
      if (setDropdowns) {
        setDropdowns((prev: DropdownsData) => ({
          ...prev,
          hobbiesAndInterests: [...prev.hobbiesAndInterests, newHobby],
        }));
      }

      formikState.setFieldValue('customHobbiesAndInterests', [...(values.customHobbiesAndInterests || []), newHobby]);
      formikState.setFieldValue('hobbiesAndInterests', [...(values.hobbiesAndInterests || []), newHobby]);

      formikState.setFieldTouched('hobbiesAndInterests', true, true);
    }
  };

  const handleSelectHobby = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedValues = event.target.value as string[];

    const selectedPredefinedHobbies = selectedValues.filter(hobby => hobbiesAndInterests.includes(hobby));

    formikState.setFieldValue('hobbiesAndInterests', selectedPredefinedHobbies);

    const customHobbies = selectedValues.filter(hobby => !hobbiesAndInterests.includes(hobby));
    formikState.setFieldValue('customHobbiesAndInterests', customHobbies);
  };

  const onDateChange = (name: string) => async (value: Dayjs | null) => {
    await formikState.setFieldValue(name, value, true);
    await formikState.setFieldTouched(name);

    if (name === 'birthday') {
      const birthdayOccasionIndex = values.occasions.findIndex(({ label }) => label.toLowerCase() === name);
      if (birthdayOccasionIndex !== -1) {
        await formikState.setFieldValue(`occasions[${birthdayOccasionIndex}].date`, value, true);
        await formikState.setFieldTouched(`occasions[${birthdayOccasionIndex}].date`);
      } else {
        await formikState.setFieldValue(`occasions`, [{ label: 'Birthday', date: value }, ...values.occasions], true);
        await formikState.setFieldTouched(`occasions[0].label`, true);
        await formikState.setFieldTouched('occasions[0].date', true);
      }
    }
  };

  return (
    <FormikProvider value={formikState}>
      <form className="flex flex-col gap-3 w-full mb-4" onSubmit={handleSubmit}>
        <h3 className="text-center mb-2 mt-7 text-green-main font-bold text-3xl">{title}</h3>
        <p className="text-center mb-5 text-green-main font-medium text-xl">
          {title === 'Create a Profile'
            ? 'Here you can create a customized profile for a friend or family member'
            : 'Here you can update your profile to allow other connections to find the perfect gift for you'}
        </p>
        {!!generalError && (
          <div className="pb-5">
            <Alert severity="error">{generalError}</Alert>
          </div>
        )}
        <Input
          placeholder="Name *"
          name="name"
          autoComplete="off"
          value={values.name}
          InputLabelProps={{ /* shrink: true, */ margin: 'dense' }}
          error={!!(touched?.name && errors?.name)}
          helperText={touched?.name && errors?.name ? errors.name : ' '}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <DatePicker
          closeOnSelect
          name="birthday"
          format={dateMask}
          defaultValue={dayjs().subtract(1, 'year')}
          minDate={dayjs().subtract(100, 'year')}
          maxDate={dayjs().subtract(1, 'year')}
          disabled={isConnectedUser}
          value={values.birthday}
          slots={{
            actionBar: getActionBar({ value: values.birthday }),
          }}
          slotProps={{
            textField: {
              placeholder: 'Birthday *',
              error: !!(touched?.birthday && errors?.birthday),
              helperText: touched?.birthday && errors?.birthday ? errors.birthday : ' ',
              onKeyDown: e => e.preventDefault(),
            },
          }}
          onAccept={onDateChange('birthday')}
          onClose={() => handleBlur({ target: { name: 'birthday' } } as any)}
        />
        <Select
          select
          name="gender"
          disabled={isConnectedUser}
          autoComplete="off"
          value={values.gender}
          error={!!(touched?.gender && errors?.gender)}
          helperText={touched?.gender && errors?.gender ? errors.gender : ' '}
          SelectProps={{
            displayEmpty: true,
            renderValue: selectRenderValue('Gender *'),
          }}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          <MenuItem hidden className="!hidden" value="" />
          {genders.map(gender => (
            <MenuItem key={gender} value={gender}>
              {gender}
            </MenuItem>
          ))}
        </Select>
        <Select
          select
          name="country"
          disabled={isConnectedUser}
          autoComplete="off"
          value={values.country}
          error={!!(touched?.country && errors?.country)}
          helperText={touched?.country && errors?.country ? errors.country : ' '}
          SelectProps={{
            displayEmpty: true,
            renderValue: selectRenderValue('Country *'),
          }}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          <MenuItem hidden className="!hidden" value="" />
          {countries.map(country => (
            <MenuItem key={country} value={country}>
              {country}
            </MenuItem>
          ))}
        </Select>

        <SelectWithInput
          select
          name="hobbiesAndInterests"
          disabled={isConnectedUser || values.hobbiesAndInterests.length >= hobbiesAndInterestsLength.MAX}
          autoComplete="off"
          value={values.hobbiesAndInterests}
          error={!!(touched?.hobbiesAndInterests && errors?.hobbiesAndInterests)}
          helperText={
            touched?.hobbiesAndInterests && errors?.hobbiesAndInterests ? errors.hobbiesAndInterests : undefined
          }
          SelectProps={{
            multiple: true,
            displayEmpty: true,
            renderValue: selectRenderValue('Hobbies and interests *'),
            onClose: () => {
              formikState.setFieldTouched('hobbiesAndInterests', true, true);
            },
          }}
          onAddHobby={handleAddHobby}
          onChange={handleSelectHobby}
          onBlur={handleBlur}
          validateHobbies={() => formikState.setFieldTouched('hobbiesAndInterests', true, true)}
          customInputPlaceholder="Add custom hobby"
        >
          <MenuItem key="" hidden className="!hidden" value="" />
          {[...hobbiesAndInterests].reverse().map((hobby, index) => (
            <MenuItem
              // eslint-disable-next-line react/no-array-index-key
              key={`${hobby}+${index}`}
              value={hobby}
              disabled={
                values.hobbiesAndInterests.length + (values.customHobbiesAndInterests?.length || 0) >= 10 &&
                !values.hobbiesAndInterests.includes(hobby)
              }
            >
              {hobby}
            </MenuItem>
          ))}
        </SelectWithInput>

        {showOccasionFields && (
          <FieldArray
            validateOnChange
            name="occasions"
            render={arrayHelpers => (
              <div className="pt-1">
                <div className="flex flex-col gap-3 w-full">
                  {!!values.occasions?.length &&
                    values.occasions.map(({ label, date }, index: number) => {
                      const key = `${index}`;
                      return (
                        <OccasionFormFields
                          key={key}
                          label={label}
                          date={date}
                          index={index}
                          touched={touched?.occasions?.[index] || {}}
                          errors={errors?.occasions?.[index] || {}}
                          arrayHelpers={arrayHelpers}
                          showDelete={values.occasions?.length > 1}
                          selectedOccasions={values.occasions}
                          occasionTypes={occasions}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          handleDateChange={onDateChange(`occasions.${index}.date`)}
                        />
                      );
                    })}
                </div>
                {values.occasions?.length < occasions?.length && (
                  <div className="pb-2">
                    <Button
                      type="button"
                      color="secondary"
                      variant="text"
                      startIcon={<AddCircleOutlineRoundedIcon />}
                      disabled={!!values.occasions.find(({ label, date }) => !label || !date)}
                      onClick={() => {
                        arrayHelpers.push({ label: '', date: null });
                        if (formikState.submitCount > 0) {
                          formikState.setFieldTouched(`occasions[${values.occasions.length}].label`, true);
                          formikState.setFieldTouched(`occasions[${values.occasions.length}].date`, true);
                        }
                      }}
                    >
                      Add Occasion
                    </Button>
                  </div>
                )}
              </div>
            )}
          />
        )}
        <Button
          type="submit"
          color="secondary"
          disabled={!isValid || loading}
          sx={{ marginTop: 4, textTransform: 'initial' }}
          startIcon={loading ? <CircularProgress size={18} /> : null}
        >
          {submitBtnLabel}
        </Button>
      </form>
    </FormikProvider>
  );
};

export default ProfileForm;
